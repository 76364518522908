import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LocalStorageData } from 'src/app/pagedata';
import { PagedataService } from 'src/app/services/pagedata.service';

@Component({
  selector: 'app-line-notification-stop-notice',
  templateUrl: './line-notification-stop-notice.component.html',
  styleUrls: ['./line-notification-stop-notice.component.scss']
})
export class LineNotificationStopNoticeComponent implements OnInit {
  constructor(private modalCtrl: ModalController, private pds: PagedataService) {}
  @Input() type: number; // 1 or 2
  public dontShowAgain = false;

  ngOnInit() {}

  public async openNotificationSetting() {
    if (this.dontShowAgain) {
      this.pds.setData(LocalStorageData.LINE_NOTIFICATION_DO_NOT_SHOW_AGAIN, true);
    }
    await this.modalCtrl.dismiss('open', 'confirm');
  }

  public async close() {
    if (this.dontShowAgain) {
      this.pds.setData(LocalStorageData.LINE_NOTIFICATION_DO_NOT_SHOW_AGAIN, true);
    }
    await this.modalCtrl.dismiss('close', 'cancel');
  }
}
