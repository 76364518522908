import { Component, ElementRef, HostListener, OnInit, ViewChild, ViewChildren, Input } from '@angular/core';

import { APP_ENABLED, LocalStorageData } from '../pagedata';
import { LayoutData } from '../interfaces/layoutData.interface';
import { ParamForReservation } from '../interfaces/paramForReservation.interface';
import { SalonData } from '../interfaces/salonData.interface';
import { SideMenu } from '../interfaces/sideMenu.interface';
import { DomainRelatedAccountData } from '../interfaces/domainRelatedAccountData.interface';
import { initLayoutData, initSalonData } from '../initData/initData';
import { PagedataService } from '../services/pagedata.service';
import { RequestParamsService } from '../services/requestParams.service';
import { HomeToHeaderService } from '../services/homeToHeader';
import { Subscription } from 'rxjs';
import { NoticeBlurActiveFromHeader } from '../services/noticeBlurActiveFromHeader';
import { NoticeBlurActiveFromHeaderForModal } from '../services/noticeBlurActiveFromHeaderForModal';
import { NavigationController } from '../services/navigationController';
import { ReservationServiceProvider } from '../services/ReservationServiceProvider';
import { MobilefooterComponent } from '../mobilefooter/mobilefooter.component';
import { MyPageService } from '../services/myPage.service';
import { ShopListMapModalService } from '../services/shopListMapModal.service';
import { ShopDetailModalService } from '../services/shopDetailModal.service';
import { AlertController, ToastController, ModalController } from '@ionic/angular';
import { NotificationsService } from '../services/notifications.service';
import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { WindowService } from '../services/windowService';
import { AccountService } from '../services/account.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() shopName: string;
  @Input() isShopDetailNav: boolean = false;

  public salonData: SalonData = initSalonData;

  // 非公開時のみfalse
  public isShowMenu: boolean = true;

  /** 通知ステータス（未登録） */
  private static CUSTOMER_NOTIFICATION_STATUS_BEFORE = 0;
  /** 通知ステータス（稼働中）*/
  private static CUSTOMER_NOTIFICATION_STATUS_SEND = 1;
  /** 通知ステータス（停止中）*/
  private static CUSTOMER_NOTIFICATION_STATUS_STOP = 2;

  layoutData: LayoutData = initLayoutData;

  isDisplayLogo = {
    isDisplayLogoOpacity: null
  };
  headerStyle = {
    centerLogo: null,
    nontransparent: false,
    noHeightTranslucent: false,
    noHeightTransparent: false
  };

  show: boolean = false;
  styleType: string;

  blur: boolean;
  showHeaderContent: boolean = false;
  hideLogo: boolean = false;
  hideMenuIcon: boolean = false;
  hideMyPageIcon: boolean = false;
  showHeaderContentMyPage: boolean = false;
  showAppConf: boolean = false;
  showHeaderContentMyPageList: boolean = false;
  showAccountCoopFilter: boolean = false;
  showAccountCoop: boolean = false;
  showShopListMobileBtn: boolean = false;

  isShowForShopModal: boolean = false;

  public notificationSettingShowing: boolean = false; // 通知設定画面の状態フラグ

  public mailNotificationStatus: number = 0; // 顧客に設定されているメール通知ステータス
  public inputCustomerMailAddress: string = null; // メールアドレス
  public customerMailAddress: string = null; // 顧客に設定されているメールアドレス
  public canRegisterMail: boolean = false; // メールアドレス登録/更新ボタン非活性フラグ
  public mailNotificationToggle: boolean = false;

  public pwaNotificationToggle: boolean = false; // PWAプッシュ通知ステータストグル
  public pwaNotificationStatus: number = 0; // 顧客に設定されているプッシュ通知ステータス

  private token: string = null; // 顧客を特定するためのtoken
  private salonAccountName: string = null; // サロンアカウント名

  //アカウント連携（ac：AccountCoop）
  acGoogle = true;
  acGoogleMailAddress: string = null;
  acFacebook = false;
  acFacebookMailAddress: string = null;

  /* マイページアイコン表示・非表示 */
  isInStandaloneMode: boolean = false;

  // 「アプリ設定」の表示/非表示
  isAppConf = false;

  headerBackgroundNone: boolean = false;
  showInstallMessageforiPad: boolean = false;
  private subscription: Subscription;
  @ViewChild('headerContentNavList', { static: false }) headerContentNavListElement: ElementRef;
  @ViewChild('header', { static: false }) headerElement: ElementRef;
  public otherMenuList: any = [];

  isShowAboutApp = false;
  gcsUrlBase: string = '';
  public loading = false;
  public isUrlKey = false; // 認証urlログイン
  public partialUserToken: string = ''; // 識別番号
  salonName: string = null;
  isOtherListOpen: boolean = false; // PCヘッダーその他メニューリストの開閉状態
  isOtherBtnChecked: boolean = false; // PCヘッダーその他メニューボタン出しわけ処理が終わったかどうか
  isSupportedNotification: boolean = false;
  loadingImage = './assets/images/icons/loading-dot.png';
  public isDebuggIOS16_3 = false;

  constructor(
    public pds: PagedataService,
    public rs: ReservationServiceProvider,
    private requestParamsService: RequestParamsService,
    public mfc: MobilefooterComponent,
    private homeToHeaderService: HomeToHeaderService,
    private noticeBlurActiveFromHeader: NoticeBlurActiveFromHeader,
    private noticeBlurActiveFromHeaderForModal: NoticeBlurActiveFromHeaderForModal,
    private navigationController: NavigationController,
    public myPageService: MyPageService,
    public shopListMapModalService: ShopListMapModalService,
    private shopDetailModalService: ShopDetailModalService,
    private alertController: AlertController,
    private toastController: ToastController,
    private notificationsService: NotificationsService,
    private modalController: ModalController,
    public router: Router,
    private clipboard: Clipboard,
    private windowService: WindowService,
    public accountService: AccountService
  ) {
    // 認証urlから通知設定の認証番号を入力し終えたら通知設定を開く
    myPageService.affiliationModalSubject$.subscribe((data) => {
      this.token = data['token'];
      const isOpenMyPage: boolean = data['isOpenMyPage'];
      if (isOpenMyPage) {
        this.openMyPage();
      }
      this.openNotificationSetting();
    });
    this.isDebuggIOS16_3 = this.pds.getIsDebuggIOS16_3();
  }

  async ngOnInit() {
    this.isSupportedNotification = await this.notificationsService.isSupportedNotification();
    this.isInStandaloneMode = this.pds.getIsStandAloneMode();
    this.load();
    // イベント登録
    // サービスで共有しているデータが更新されたら発火されるイベントをキャッチする
    this.subscription = this.homeToHeaderService.sharedDataSource$.subscribe((msg) => {
      this.showInstallMessageforiPad = msg;
    });

    // 本部からアドバンスプランを開いたときに、モーダル左上の戻るボタンをクリックしたときの戻るイベント
    window.addEventListener('message', function (event) {
      if ('pageBack' == event.data) {
        if (window.location.href.match(/backShopSelect/)) {
          // iframe内で表示しているURLに「#backShopSelect」が含まれる場合は、iframeを閉じるように指示を送る
          window.parent.postMessage('backShopSelect', event.origin);
        } else {
          // それ以外は戻るイベントを実行
          this.history.back();
        }
      }
    });

    // 店舗詳細で開くモーダル名がセットされている場合
    if (this.pds.openModalSalonName) {
      // モーダルのチェック
      this.checkOpenModal();
    }

    // actionがセットされている場合
    switch (this.pds.action) {
      case 'openShopSelect':
        // モーダルを開いていなければ
        if (!this.isShopDetailNav) {
          // 店舗詳細を表示した状態のモーダルを開く
          this.shopListMapModalService.openShopListMapModal();
        }
        break;
      default:
        break;
    }
  }

  ngAfterViewChecked() {
    this.checkShowOtherBtnVisibility();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.closeNavList();
  }

  private async load() {
    this.loading = false;
    const data = await this.pds.getLayoutData(this.shopName);
    this.salonData = await this.pds.getSalonData(this.shopName);

    const params = this.requestParamsService.getRequestParams();
    this.isUrlKey = params.key != null;
    const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
    this.salonAccountName = accountData.accountName != null ? accountData.accountName : null;

    // 本部orアドバンス店舗（本部あり）で、店舗詳細モーダルで開いていない場合に「店舗一覧」ボタンを開く
    if (
      (this.salonData['appEnabled'] == APP_ENABLED.PWA_APP_ENTERPRISE_COMPANY ||
        (this.salonData['companyId'] != null && this.salonData['appEnabled'] == APP_ENABLED.PWA_APP_ADVANCE)) &&
      !this.isShopDetailNav
    ) {
      this.showShopListMobileBtn = true;
    }

    this.set(data, this.salonData);
  }

  // モーダルの表示チェック
  private async checkOpenModal() {
    // URLの本部ドメインと詳細店舗の紐付きチェック
    const isShopdetailRelSalon = await this.pds.checkShopdetailRelSalon(this.pds.openModalSalonName);
    // モーダルを開いておらず、かつURLの本部ドメインと詳細店舗の紐付きが正しい場合
    if (!this.isShopDetailNav && isShopdetailRelSalon) {
      // 店舗詳細を表示した状態のモーダルを開く
      this.shopDetailModalService.openShopDetailModal(this.pds.openModalSalonName, this.pds.openModalPageName);
    }
  }

  set(data: any, salonData: any) {
    const params = this.requestParamsService.getRequestParams();
    if (
      (params.isPreview && params.isPrivate) ||
      ((!salonData.isPublic || salonData.publishedDate == null) && !this.pds.getPreview())
    ) {
      this.isShowMenu = false;
    }
    this.layoutData = data;

    console.log('layout');
    console.log(this.layoutData);

    this.salonName = salonData.name;
    this.isDisplayLogo.isDisplayLogoOpacity = !this.layoutData.isDisplayLogo;
    this.headerStyle.centerLogo = this.layoutData.centerLogo;
    const headerBgStyle = this.layoutData.headerBg;
    this.headerStyle[headerBgStyle] = true;
  }

  openNav() {
    if (this.show === false) {
      this.showHeaderContent = true;
      this.hideLogo = true;
      this.hideMenuIcon = true;
      this.headerBackgroundNone = true;
      if (this.isShopDetailNav) {
        this.noticeBlurActiveFromHeaderForModal.onNotifySharedDataChanged(true);
      } else {
        this.noticeBlurActiveFromHeader.onNotifySharedDataChanged(true);
      }
      this.hideMyPageIcon = true;
    }
    this.show = !this.show;
    // ハンバーガーメニューを開いたとき、子メニュー、その他メニューのopenクラスを外す
    this.closeNavList();
  }

  openMyPage() {
    this.showHeaderContentMyPage = true;
    this.showHeaderContentMyPageList = true;

    if (this.isShopDetailNav) {
      this.noticeBlurActiveFromHeaderForModal.onNotifySharedDataChanged(true);
    } else {
      this.noticeBlurActiveFromHeader.onNotifySharedDataChanged(true);
    }
    this.hideLogo = true;
    this.hideMenuIcon = true;
    this.headerBackgroundNone = true;
    this.hideMyPageIcon = true;
    // 識別番号を表示
    this.getPartialUserToken();
  }

  async openMypageAndNotificationPage() {
    this.openMyPage();
    await this.clickNotificationSetting();
  }

  closeNav() {
    this.showHeaderContent = false;
    this.showHeaderContentMyPageList = false;
    if (this.isShopDetailNav) {
      this.noticeBlurActiveFromHeaderForModal.onNotifySharedDataChanged(false);
    } else {
      this.noticeBlurActiveFromHeader.onNotifySharedDataChanged(false);
    }
    this.hideLogo = false;
    this.hideMenuIcon = false;
    this.headerBackgroundNone = false;
    this.show = !this.show;
    this.hideMyPageIcon = false;
    // ハンバーガーメニューを閉じたとき、子メニュー、その他メニューのopenクラスを外す
    this.closeNavList();
  }

  closeMyPage() {
    this.showHeaderContentMyPage = false;
    if (this.isShopDetailNav) {
      this.noticeBlurActiveFromHeaderForModal.onNotifySharedDataChanged(false);
    } else {
      this.noticeBlurActiveFromHeader.onNotifySharedDataChanged(false);
    }
    this.hideLogo = false;
    this.hideMenuIcon = false;
    this.headerBackgroundNone = false;
    this.hideMyPageIcon = false;
  }

  // メニュー吹き出しが開いていたら閉じる
  closeNavList(isCloseOtherChildMenu = true) {
    this.layoutData.menus.forEach((menu) => {
      if (isCloseOtherChildMenu) {
        menu.isOtherChildMenuOpen = false;
      }
      menu.isChildMenuOpen = false;
    });
    this.isOtherListOpen = false;
  }

  closeNavLinkClick() {
    this.showHeaderContent = false;
    this.hideLogo = false;
    this.hideMenuIcon = false;
    this.headerBackgroundNone = false;
    this.hideMyPageIcon = false;
    this.noticeBlurActiveFromHeader.onNotifySharedDataChanged(false);
    this.show = !this.show;
    this.closeNavList();
  }

  openAppConf() {
    this.showHeaderContentMyPageList = false;
    this.showAppConf = true;
  }

  backMyPage() {
    this.showHeaderContentMyPageList = true;
    this.showAppConf = false;
    this.isShowAboutApp = false;
    this.notificationSettingShowing = false;
  }

  openAboutApp() {
    this.showHeaderContentMyPageList = false;
    this.isShowAboutApp = true;
  }

  // 通知設定画面を開く
  private async openNotificationSetting() {
    this.loading = true;

    const pwa = await this.rs.findPwaData(this.token).catch((error) => {
      console.error(error);
      return;
    });
    const result = await this.rs
      .findNotificationStatus(this.salonAccountName, this.token, this.pds.getIsCompany())
      .catch((error) => {
        return error;
      });
    if (result.error && result.error === 'no customer') {
      // 初回の予約前。顧客データが作られていない場合は通知設定を開かせない
      this.loading = false;
      await this.errorNoCustomerAlert();
      this.backMyPage();
      return;
    }

    this.showHeaderContentMyPageList = false;
    this.notificationSettingShowing = true;

    // プッシュトークンが登録できていない
    if (
      (await this.notificationsService.isSupportedNotification()) &&
      pwa.pushTokenSuccsess != null &&
      !pwa.pushTokenSuccsess
    ) {
      if (this.pds.getIsIos()) {
        // iOSではリクエストを出す前だとNotificationsService.permissionStatus.deniedが取得できないのでリクエストを出す
        // アプリ起動画面でgranted or denied を選択しているはずなのでこのrequestPermission()は何も起きない
        await this.notificationsService.requestPermission();
      }
      const pushTokenResult = await this.registerPushToken();
      if (!pushTokenResult) {
        // プッシュトークンがないのでダメ
        this.loading = false;
        this.backMyPage();
        return;
      }
    }

    this.refreshNotificationStatus();
  }

  // メール通知ボタン
  public clickMailNotification() {
    // メアド登録or変更メソッド
    this.notificationSettingShowing = false;
  }

  // プッシュ通知トグル変更
  public async onChangePwaNotification(event: CustomEvent) {
    const isSupported = await this.notificationsService.isSupportedNotification();
    if (!isSupported) {
      return;
    }

    const isCooperation: boolean = event.detail.checked;
    if (Notification.permission === NotificationsService.permissionStatus.denied && isCooperation) {
      // ユーザーが明示的に拒否。設定から許可する必要あり
      await this.errorPermissionAlert();
      this.refreshNotificationStatus();
    } else if (Notification.permission === NotificationsService.permissionStatus.default) {
      // デフォルト。再度通知許可を聞く
      if (isCooperation) {
        const isPermission = await this.notificationsService.requestPermission();
        // 許可されたらステータスも更新
        if (isPermission) {
          this.changedPwaNotification(isCooperation);
        } else {
          this.refreshNotificationStatus();
        }
      } else {
        // OFFにするときは許可を求める必要なし
        this.changedPwaNotification(isCooperation);
      }
    } else if (Notification.permission === NotificationsService.permissionStatus.granted) {
      // 許可済み
      this.changedPwaNotification(isCooperation);
    }
  }

  /**
   * PWA通知ステータス更新
   * @param isCooperation True:連携する False:解除する
   */
  public async changedPwaNotification(isCooperation: boolean) {
    const result: any = await this.rs
      .registerOrUpdatePwaNotification(this.salonAccountName, this.token, isCooperation, this.pds.getIsCompany())
      .catch((error) => {
        return error;
      });
    if (result.error) {
      // PWA通知ステータス更新エラー
      if (result.error === 'no customer' || result.error === 'no salon') {
        // 顧客削除、サロン削除は処理終了
        return;
      } else if (result.error === 'no pushToken') {
        // プッシュトークンが登録されていないので登録し直し
        this.notificationsService.requestPermission();
      } else {
        // その他のエラー、処理終了
        return;
      }
    } else if (result.result) {
      // 更新成功
    }

    this.refreshNotificationStatus();
  }

  /**
   * メール通知ステータス更新
   * @param event True:連携する False:解除する
   */
  public async changedMailNotification(event: CustomEvent) {
    const isCooperation: boolean = event.detail.checked;
    if (isCooperation) {
      // OFF→ON
      // ステータスを稼働中に変更
      const result: any = await this.rs
        .registerOrUpdateMailNotification(this.salonAccountName, this.token, isCooperation, this.pds.getIsCompany())
        .catch((error) => {
          return error;
        });
      if (result.error) {
        console.error(result.error);
      }
    } else if (!isCooperation) {
      // ON→OFF
      // ステータスを顧客停止に変更
      const result: any = await this.rs
        .registerOrUpdateMailNotification(this.salonAccountName, this.token, isCooperation, this.pds.getIsCompany())
        .catch((error) => {
          return error;
        });
      if (result.error) {
        console.error(result.error);
      }
    }
    this.refreshNotificationStatus();
  }

  /**
   * メールアドレスの登録or更新
   */
  public async registerAndUpdatedMailAddress() {
    this.loading = true;
    const result = await this.rs
      .registerPwaCustomerMailAddress(
        this.salonAccountName,
        this.inputCustomerMailAddress,
        false,
        this.token,
        this.pds.getIsCompany()
      )
      .catch((error) => {
        return error;
      });
    this.loading = false;
    if (result.error) {
      if (result.error === 'mail exists already') {
        // 既に他の顧客にメールアドレスが使われている
        await this.errorAlreadyMailAlert();
      } else {
        // 他のエラー
        await this.errorMailAlert();
      }
    } else {
      // 登録or更新は実在性確認メール送信
      await this.verificationMailAlert();
    }
  }

  // メールアドレスインプットを操作する度に呼ばれる
  public onChangeMailAddress(event: CustomEvent) {
    // メールアドレスが前回と変わらないか0文字のときはボタン非活性
    if (this.inputCustomerMailAddress === this.customerMailAddress || !this.inputCustomerMailAddress.length) {
      this.canRegisterMail = false;
    } else {
      this.canRegisterMail = true;
    }
  }

  /**
   * 通知ステータスを取得
   */
  private async refreshNotificationStatus() {
    this.loading = true;

    const result = await this.rs
      .findNotificationStatus(this.salonAccountName, this.token, this.pds.getIsCompany())
      .catch((error) => {
        return error;
      });

    if (await this.notificationsService.isSupportedNotification()) {
      // 「許可」以外の場合は必ずOFFにする
      if (Notification.permission !== NotificationsService.permissionStatus.granted) {
        this.pwaNotificationToggle = false;
        this.loading = false;
        return;
      }

      // プッシュ通知のステータス
      // プッシュトークンがない場合はOFFにする
      const isPushToken: boolean = result.pushTokenSuccsess != null && result.pushTokenSuccsess;
      this.pwaNotificationStatus = result.pwaNotificationStatus;
      // ステータスがデフォルト値 or 「稼働中」ならトグルONにする
      this.pwaNotificationToggle =
        isPushToken &&
        (this.pwaNotificationStatus == null ||
          this.pwaNotificationStatus === HeaderComponent.CUSTOMER_NOTIFICATION_STATUS_SEND);
    } else {
      // プッシュ通知が利用できないiOS（iOS16.4 > x）

      // メールの通知ステータス
      this.mailNotificationStatus = result.mailDeliverStatus;
      this.customerMailAddress = result.mailAddress;
      this.mailNotificationToggle = this.mailNotificationStatus === HeaderComponent.CUSTOMER_NOTIFICATION_STATUS_SEND; // 稼働中ならトグルON

      // メールアドレス初期値セット
      this.inputCustomerMailAddress = this.customerMailAddress;
    }
    this.loading = false;
  }

  /**
   *プッシュトークンが登録できていないときに登録を試みる
   * @param notificationStatus
   * @return True:トークン登録成功 False：登録失敗
   */
  private async registerPushToken(): Promise<boolean> {
    if (Notification.permission === NotificationsService.permissionStatus.granted) {
      // 通知許可済み
      const alertResult = await this.notificationsService.pushTokenUnRegisteredAlert(
        'プッシュ通知の登録が完了していません。「次へ」を押すとプッシュ通知の登録が完了します。'
      );
      if (alertResult) {
        const pushTokenResult = await this.notificationsService.requestPermission();
        if (pushTokenResult) {
          await this.notificationsService.pushTokenSuccess();
          return true;
        }
      }
      return false;
    } else if (Notification.permission === NotificationsService.permissionStatus.default) {
      // デフォルト（あとで）
      const alertResult = await this.notificationsService.pushTokenUnRegisteredAlert(
        'プッシュ通知の登録が完了していません。<br>次の画面で「許可」を押すとプッシュ通知の登録が完了します。'
      );
      if (alertResult) {
        const pushTokenResult = await this.notificationsService.requestPermission();
        if (pushTokenResult) {
          await this.notificationsService.pushTokenSuccess();
          return true;
        }
      }
      return false;
    } else if (Notification.permission === NotificationsService.permissionStatus.denied) {
      //　通知拒否
      this.errorPermissionAlert();
      return false;
    }
    return false;
  }

  // 実在性確認メールを送信したダイアログ
  private async verificationMailAlert() {
    const alert = await this.alertController.create({
      cssClass: '',
      message: '確認メールを送信しました。<br>' + 'メールを確認してください。',
      buttons: [
        {
          text: 'OK'
        }
      ]
    });

    await alert.present();
  }

  private async errorAlreadyMailAlert() {
    const alert = await this.alertController.create({
      cssClass: '',
      message: 'このメールアドレスは既に使われています。<br>' + '別のメールアドレスを利用してください。',
      buttons: [
        {
          text: 'OK'
        }
      ]
    });

    await alert.present();
  }

  private async errorMailAlert() {
    const alert = await this.alertController.create({
      cssClass: '',
      message: '登録できませんでした。<br>' + 'もう一度やり直してください。',
      buttons: [
        {
          text: 'OK'
        }
      ]
    });

    await alert.present();
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: 'メールアドレスを登録しました。',
      duration: 2000,
      position: 'middle'
    });
    toast.present();
  }

  /**
   * 通知を拒否していた場合のアラート
   */
  private async errorPermissionAlert() {
    const alert = await this.alertController.create({
      cssClass: '',
      message: 'デバイスの設定から通知を許可してください。',
      buttons: [
        {
          text: 'OK'
        }
      ]
    });

    await alert.present();
  }

  private async errorNoCustomerAlert() {
    const alert = await this.alertController.create({
      cssClass: '',
      message: 'この設定を利用するには予約履歴が必要です。',
      buttons: [
        {
          text: 'OK'
        }
      ]
    });

    await alert.present();
  }

  /**
   * アカウント連携取得
   */
  async openAccountCoop() {
    const userData = await this.pds.getDataPromise(LocalStorageData.USER_DATA);
    const token: string = userData != null ? userData.token : null;

    this.rs
      .findMyOwnerCredentialData(token)
      .then((result) => {
        if (token) {
          this.acGoogle = result.googleUserId != null;
          this.acFacebook = result.facebookUserId != null;

          this.acGoogleMailAddress = result.googleMailAddress;
          this.acFacebookMailAddress = result.facebookMailAddress;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this.showAccountCoopFilter = true;
    this.showAccountCoop = true;
  }

  /**
   * アカウント連携
   * @param linkName 連携するサービス（Google,Facebook)
   */
  async linkAccountCoop(linkName: string) {
    const userData = await this.pds.getDataPromise(LocalStorageData.USER_DATA);
    const token: string = userData != null ? userData.token : null;

    const params = this.requestParamsService.getRequestParams();
    const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
    const salonName: string = accountData.accountName != null ? accountData.accountName : null;

    this.rs.findTempTokenByToken(token).then((result) => {
      if (result && result.tempToken) {
        const tempToken: string = result.tempToken;
        // TODO:検証用と商用でドメインを変える

        let url = this.layoutData['ucsUrl'] + 'gcs/user/';
        if (linkName === 'Google') {
          url = `${url}googleLogin.do?salonAccount=${salonName}&pageName=accountCoop&tempToken=${tempToken}`;
        } else if (linkName === 'Facebook') {
          url = `${url}facebookLogin.do?salonAccount=${salonName}&pageName=accountCoop&tempToken=${tempToken}`;
        }

        setTimeout(() => {
          window.open(url, '_blank');
        }, 20);
      }
    });
  }
  /**
   * アカウント連携解除
   * @param linkName 解除するサービス（Google,Facebook)
   */
  async cancelAccountCoop(linkName: string) {
    const userData = await this.pds.getDataPromise(LocalStorageData.USER_DATA);
    const token: string = userData != null ? userData.token : null;

    this.rs.removeLinkMyOwnerCredential(token, linkName).then((result) => {
      this.closeAccountCoop();
      this.openAccountCoop();
    });
  }

  closeAccountCoop() {
    this.showAccountCoopFilter = false;
    this.showAccountCoop = false;
  }

  judgementAccountCoopRegion(event) {
    // 「アカウント連携」モーダル外側のグレー部分 or ×ボタン　の場合モーダルを閉じる
    if (!event.target.classList.contains('accountCoop_contents')) {
      this.closeAccountCoop();
    }
  }

  private resetMyPage() {
    this.showHeaderContentMyPageList = false;
    this.showHeaderContentMyPage = false;
    if (this.isShopDetailNav) {
      this.noticeBlurActiveFromHeaderForModal.onNotifySharedDataChanged(false);
    } else {
      this.noticeBlurActiveFromHeader.onNotifySharedDataChanged(false);
    }
    this.hideLogo = false;
    this.hideMenuIcon = false;
    this.headerBackgroundNone = false;
    this.hideMyPageIcon = false;
  }

  closeMyPageAndAppConf() {
    this.resetMyPage();
  }

  closeMyPageAndAboutApp() {
    this.resetMyPage();
    this.isShowAboutApp = false;
  }

  public closeNotificationDetail() {
    this.resetMyPage();
    this.notificationSettingShowing = false;
  }

  public clickBooking() {
    // エンタープライズはmap画面に飛ばす
    let pageName = '';
    if (this.pds.getIsCompany()) {
      // 本部の場合
      pageName = this.isShopDetailNav ? MyPageService.RESERVATION : MyPageService.MAP;
    } else {
      // 本部以外の場合（通常のアドバンス、ベーシックの場合）
      pageName = MyPageService.RESERVATION;
    }
    this.myPageService.authenticateKey(pageName, this.defaultParamForReservation());
  }

  public async clickReservationHistory() {
    this.myPageService.authenticateKey(MyPageService.RESERVATION_LIST, this.defaultParamForReservation());
  }

  public async clickShareImageList() {
    this.myPageService.authenticateKey(MyPageService.SHARED_IMAGES, this.defaultParamForReservation());
  }

  public async clickMessage() {
    this.myPageService.authenticateKey(MyPageService.MESSAGE_LIST, this.defaultParamForReservation());
  }

  async clickPoint() {
    this.myPageService.authenticateKey(MyPageService.POINT, this.defaultParamForReservation());
  }

  public async clickHandShake() {
    this.myPageService.authenticateKey(MyPageService.HAND_SHAKE, this.defaultParamForReservation());
  }

  /**
   * アプリについてから通知設定を開く
   */
  public async closeAboutAppAndOpenNotification() {
    this.isShowAboutApp = false;
    this.clickNotificationSetting();
  }

  /**
   * 通知設定をタップ
   */
  public async clickNotificationSetting() {
    const params = this.requestParamsService.getRequestParams();
    if (this.isUrlKey) {
      // 認証urlからの操作
      const key = params.key;
      const result = await this.rs.findOwnerCredentialByKey(key).catch((error) => {
        return error;
      });

      let token: string = null;

      // keyが無効、有効期限切れ
      if (result.error) {
        return await this.myPageService.openModal(
          MyPageService.NOTIFICATION_SETTING,
          true,
          this.defaultParamForReservation()
        );
      } else if (result.message == 'no authentication required') {
        // key有効　認証番号不要
        token = result.token;
      } else if (result.message == 'authentication required') {
        // key有効　認証番号必要
        await this.myPageService.openModal(
          MyPageService.NOTIFICATION_SETTING,
          false,
          this.defaultParamForReservation()
        );
      }

      // tokenが取得できなかったら何もしない
      if (token == null) {
        return;
      }
      // tokenをセット
      this.token = token;
      this.openNotificationSetting();
    } else {
      // アプリからの操作
      const userData = await this.pds.getDataPromise(LocalStorageData.USER_DATA);
      this.token = userData != null ? userData.token : null;
      this.openNotificationSetting();
    }
  }

  public async clickDeleteAccount() {
    const alert = await this.alertController.create({
      cssClass: 'deleteAccountMsg',
      header: 'アプリからすべてのデータを削除してよろしいですか？',
      message: '予約履歴、メッセージの確認などができなくなります。',
      buttons: [
        {
          text: 'すべてのデータを削除',
          role: 'destructive',
          cssClass: 'deleteAccountMsg_delete order-last',
          handler: async () => {
            this.pds.allClearData();
            // 再起動
            location.href = `${location.origin}/home`;
          }
        },
        {
          text: 'キャンセル',
          role: 'cancel',
          cssClass: 'order-first'
        }
      ]
    });

    await alert.present();
  }

  // 顧客情報削除
  public async clickDeleteCustomerData() {
    const alert = await this.alertController.create({
      cssClass: 'deleteCustomerMsg',
      header: '',
      message:
        '<div class="align-left">' +
        'お客様情報の削除を依頼します。\n' +
        '<br>' +
        '<br>' +
        'サロンで確認後、以下のすべてが削除されます。\n' +
        '<ul class="default margin-y-2"><li>過去の予約履歴</li>' +
        '<li>サロンから共有された画像</li>' +
        '<li>過去のご来店時の電子レシート</li>' +
        '<li>サロンに登録されているお客様情報すべて</li>' +
        '</ul> ' +
        '<span class="default color-red">削除すると上記データの復元はできませんので十分ご注意ください。</span>' +
        '</div>',
      buttons: [
        {
          text: 'キャンセル',
          role: 'cancel',
          cssClass: 'order-first'
        },
        {
          text: '削除',
          role: 'destructive',
          cssClass: 'deleteAccountMsg_delete order-last',
          handler: async () => {
            const userData = await this.pds.getDataPromise(LocalStorageData.USER_DATA);
            const token: string = userData !== null ? userData.token : null;
            const result = await this.rs.removeMyCustomerData(token, -1).catch((error) => {
              return error;
            });
            if (
              result.error &&
              result.error.ex &&
              result.error.ex.cause &&
              result.error.ex.cause.message === 'unable to remove customer'
            ) {
              // 未来予約があって顧客情報削除できない
              this.alertFutureReservation();
            } else {
              // 顧客情報削除が終わったらローカルデータを削除して再起動
              this.pds.allClearData();
              location.href = `${location.origin}/home`;
            }
          }
        }
      ]
    });

    await alert.present();
  }

  public async alertFutureReservation() {
    const alert = await this.alertController.create({
      cssClass: 'deleteAccountMsg',
      header: '本日以降に予約があるためお客様情報を削除できません',
      message: '「予約の確認・変更」で予約をキャンセルしてからお客様情報を削除してください。',
      buttons: [
        {
          text: '予約の確認・変更',
          handler: async () => {
            this.clickReservationHistory();
          }
        }
      ]
    });
    await alert.present();
  }

  /**
   * 「アプリ設定」の表示/非表示
   */
  // 2021年現在利用停止中
  async isCustomerId(): Promise<void> {
    const userData = await this.pds.getDataPromise(LocalStorageData.USER_DATA);
    const token: string = userData != null ? userData.token : null;

    this.rs
      .findPWAReservationAppSalonData(token)
      .then((result) => {
        // customerId > 0　のお気に入りサロンが見つかれば「アプリ設定」を表示する
        const appSalonList = result.reservationAppSalonList;
        if (appSalonList && appSalonList.length) {
          const appSalonData = appSalonList.find((appSalon) => appSalon.customerId > 0);

          if (appSalonData) {
            return (this.isAppConf = true);
          }
        }
        return (this.isAppConf = false);
      })
      .catch((error) => {
        console.log(error);
        return (this.isAppConf = false);
      });
  }

  // PC吹き出し内クリック時
  clickOtherChildMenu(menu) {
    if (menu.childMenus.length > 0) {
      // PC吹き出し内新規ページ・新規ページ（子）の開閉処理
      menu.isOtherChildMenuOpen = !menu.isOtherChildMenuOpen;
    } else {
      // ページ遷移
      this.clickLink(menu.name);
    }
  }

  // PC吹き出し外メニュークリック時
  clickChildMenu(clickedMenu: SideMenu) {
    if (clickedMenu.childMenus.length > 0) {
      clickedMenu.isChildMenuOpen = !clickedMenu.isChildMenuOpen;
      // モバイル場合、開いたメニューは開きっぱなしにする
      if (!this.windowService.isMobileView) {
        // PCの場合、他に開いているメニューは閉じる
        this.layoutData.menus.forEach((menu) => {
          if (menu.id !== clickedMenu.id) {
            menu.isChildMenuOpen = false; // 全ての isOpen プロパティを false に設定
          }
        });
      }
      this.isOtherListOpen = false;
    } else {
      // ページ遷移
      this.clickLink(clickedMenu.name);
    }
  }

  toggleOtherMenu() {
    console.log('toggleOtherMenu');
    this.isOtherListOpen = !this.isOtherListOpen;

    this.layoutData.menus.forEach((menu) => {
      menu.isChildMenuOpen = false; // 全ての isOpen プロパティを false に設定
    });
  }

  // 描画が完了してから実行する処理。
  // ヘッダーの要素がはみ出ているか？を元にPCヘッダーその他メニューリストの開閉状態を切り替える。
  checkShowOtherBtnVisibility() {
    if (this.windowService.isMobileView) {
      return;
    }
    this.otherMenuList = [];
    // ヘッダーメニューの親要素を取得
    const headerContentNavList = this.headerContentNavListElement;
    if (headerContentNavList == null) {
      return;
    }
    const headerMenuTop = headerContentNavList.nativeElement; // 要素の上位置を取得
    if (headerMenuTop.offsetTop === 0) {
      return;
    }
    // ヘッダーメニュー全要素を取得
    const headerMenuElements = this.headerContentNavListElement.nativeElement.children;
    const headerMenuItems = Array.from(headerMenuElements).filter((element: HTMLElement) => {
      return element.classList.contains('header_content_nav__item');
    });
    for (const item of headerMenuItems) {
      const element = item as HTMLElement;
      if (element.offsetTop > headerMenuTop.offsetTop) {
        // 溢れたメニュー
        const id = element.id.replace('item_', '');
        // otherメニューに表示されていないメニューのデータを追加する
        this.otherMenuList.push(this.layoutData.menus.filter((menu) => menu.id == id)[0]);
      }
    }
  }

  closeInstallMessage() {
    this.showInstallMessageforiPad = false;
  }

  // 識別番号表示
  async getPartialUserToken() {
    const userData = await this.pds.getDataPromise(LocalStorageData.USER_DATA);
    const token: string = userData != null ? userData.token : null;
    const partial = token != null && token.length >= 10 ? token.substring(0, 10) : 'ERROR';
    this.partialUserToken = partial;
  }

  clickLink(link, requestParams = {}) {
    if (this.isShopDetailNav) {
      this.shopDetailModalService.pushPage(this.shopName, link, requestParams);
    } else {
      // pageNameはモーダルでは使用しないので、パラメータを削除する
      delete requestParams['pageName'];
      // モーダルを閉じた状態でクリックした場合、通常のページ遷移を行う
      this.router.navigate(['/' + link], { queryParams: requestParams });
      this.navigationController.setClickedRouterLink(true);
    }
    this.closeNav();
  }

  clickChildListMenu(parent, child) {
    if (parent === 'gallery') {
      this.clickLink(parent, { brand_id: child.replace('gallery_', '') });
    } else {
      this.clickLink(child, { pageName: child });
    }
  }

  // 店舗一覧・地図モーダルを開く
  openShopListMapModal() {
    this.shopListMapModalService.openShopListMapModal();
  }

  async shareURL() {
    const navigator = window.navigator as any;
    let url = 'https://' + window.location.host + '/?openExternalBrowser=1';
    // 店舗詳細モーダルで「友だちに紹介」をした場合、店舗詳細を開いている店舗の情報をURLに含める
    if (this.isShopDetailNav) {
      url = 'https://' + window.location.host + '/?shopDetail=' + this.shopName + '&openExternalBrowser=1';
    }
    if (navigator.share) {
      navigator
        .share({
          title: this.salonName,
          text: this.salonName,
          url: url
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      this.clipboard.copy(url);
      const alert = await this.alertController.create({
        message:
          'ご利用中のブラウザは「友だちに紹介」に対応していません。URLをコピーしたので、SNSなどにペーストしてご使用ください。',
        buttons: ['OK']
      });
      await alert.present();
    }
  }

  // 付加情報のないパラメータ
  private defaultParamForReservation = () => {
    const param: ParamForReservation = {
      staffId: -1,
      lastReservation: -1,
      salonName: this.isShopDetailNav ? this.shopName : ''
    };
    return param;
  };

  public debugIOS16_3Enabled() {
    return this.pds.getIsIos() && this.pds.pwaEnv === 'staging';
  }

  public onChangeIsDebugIOS16_3(event: CustomEvent) {
    const isDebugg: boolean = event.detail.checked;
    this.isDebuggIOS16_3 = isDebugg;
    this.pds.setIsDebuggIOS16_3(isDebugg);

    // 画面リロード
    location.reload();
  }
}
