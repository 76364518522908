import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from './../../environments/environment';
import { RequestParamsService } from './requestParams.service';
import { PagedataApiService } from './pagedataApi.service';
import { MetaDataController } from './metaDataController';
import { LocalStorageData, APP_ENABLED } from '../pagedata';
import { MenuPageData } from '../interfaces/menuPageData.interface';
import { ThemeData } from '../interfaces/themeData.interface';
import { LayoutData } from '../interfaces/layoutData.interface';
import { HomePageData } from '../interfaces/homePageData.interface';
import { StaffPageData } from '../interfaces/staffPageData.interface';
import { StaffDetailPageData } from '../interfaces/staffDetailPageData.interface';
import { SalonPageData } from '../interfaces/salonPageData.interface';
import { GalleryPageData } from '../interfaces/galleryPageData.interface';
import { CampaignPageData } from '../interfaces/campaignPageData.interface';
import { NewsPageData } from '../interfaces/newsPageData.interface';
import { NewsDetailPageData } from '../interfaces/newsDetailPageData.interface';
import { GallerySummaryData } from '../interfaces/gallerySummaryData.interface';
import { FreePageData } from '../interfaces/freePageData.interface';
import { ShopListData } from '../interfaces/shopListData.interface';
import { SalonData } from '../interfaces/salonData.interface';
import { DomainRelatedAccountData } from '../interfaces/domainRelatedAccountData.interface';
import { initDomainRelatedAccountData, initSalonData } from '../initData/initData';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { UAParser } from 'ua-parser-js';
import { StorageService, UserData } from './StorageService';
import * as WebFont from 'webfontloader';
import { AccountService } from './account.service';
import { AppComponent } from '../app.component';
import { WindowService } from './windowService';
// --------------------------
// src/assets/js/customColor.js
declare let lightenColor: any;
declare let HEXtoRGB: any;
// --------------------------

@Injectable({
  providedIn: 'root'
})
export class PagedataService {
  public openModalSalonName: string = null;
  public openModalPageName: string = null;
  public viewMode: string = null;
  public isReload: boolean = false;
  public action: string = null;
  public pageName: string = null;
  private isPreview: boolean = false;
  private isStandAloneMode: boolean = null; // スタンドアロンで起動しているかどうか
  private isAndroid: boolean = false; // アンドロイドかどうか
  private isDeprecation: boolean = true; // 対象外のブラウザかどうか（対象外ブラウザ：true、chrome/SamsungInternetBrowser：false）
  private isOldsamsungbrowser: boolean = false; // メジャーバージョンが12以下のSamsungInternetBrowser（マイページが使えない）
  private paramKey: string = null;
  private isIos: boolean = false; // iOSかどうか
  private isSafari: boolean = false; // サファリかどうか
  private isiPad: boolean = false; // iPadかどうか
  private modelName = null;
  private osVersion;
  private osName: string = null;
  private osMajorVersion: number = null;
  private layoutData: LayoutData = null;
  private themeData: ThemeData = null;
  private themeDataEnterpriseShopList: any = {};
  private salonData: SalonData = null;
  public openHomeList: any = {};
  public isInaccessibleSoon: boolean = false; // 本部公開から60日以内のアクセスの場合のフラグ
  public isInaccessible: boolean = false; // 本部公開から60日以降のアクセスの場合のフラグ
  public isCompanyForLocal: boolean = false; // ローカル用本部かどうか？フラグ
  public ownDomainList: Array<{ salonId: number; accountName: string; ownDomain: string }> = []; // 独自ドメインのリスト（全店のリスト。）
  public isPwaApp: boolean = false; // PWAアプリかどうか（keyつきURLでもfalseのまま。独自ドメインのリダイレクト可否・アプリ起動時の店舗詳細モーダルを開くかどうかに利用）
  public newUrlForEnterprise: string = ''; // 本部ドメインの新しいURL
  private isAnnouncementSalon: boolean = false; // 本部PWAでアプリの告知が「店舗」かどうか（falseの場合は、本部PWAでアプリの告知が「本部」 or この機能に関係のない店舗版PWA）
  public ANNOUNCEMENT_SALON = 1;

  shopDetailModalStyles = {
    theme0101: false,
    theme0102: false,
    theme0103: false,
    theme0104: false,
    theme0105: false,

    theme0201: false,
    theme0202: false,
    theme0203: false,
    theme0204: false,
    theme0205: false,
    theme0206: false,

    theme0301: false,
    theme0302: false,
    theme0303: false,
    theme0304: false,
    theme0305: false,
    theme0306: false,
    theme0307: false,

    h_theme0101: false,
    h_theme0102: false,
    h_theme0103: false,

    h_theme0201: false,
    h_theme0202: false,
    h_theme0203: false,
    h_theme0204: false,
    h_theme0205: false,

    h_theme0301: false,
    h_theme0302: false,
    h_theme0303: false
  };
  shopListMapModalStyles = {
    theme0101: false,
    theme0102: false,
    theme0103: false,
    theme0104: false,
    theme0105: false,

    theme0201: false,
    theme0202: false,
    theme0203: false,
    theme0204: false,
    theme0205: false,
    theme0206: false,

    theme0301: false,
    theme0302: false,
    theme0303: false,
    theme0304: false,
    theme0305: false,
    theme0306: false,
    theme0307: false,

    h_theme0101: false,
    h_theme0102: false,
    h_theme0103: false,

    h_theme0201: false,
    h_theme0202: false,
    h_theme0203: false,
    h_theme0204: false,
    h_theme0205: false,

    h_theme0301: false,
    h_theme0302: false,
    h_theme0303: false
  };
  public pwaEnv: string = 'production';
  private PLARTFORM_IOS = 'iOS';
  private PLARTFORM_IPAD = 'iPad';
  private PLATFORM_ANDROID = 'Android';
  private PLATFORM_PWA = 'PWA';
  private isDebuggIOS16_3 = false;
  private IS_DEBUGG_IOS16_3 = 'isDebuggIOS16_3';

  constructor(
    private requestParamsService: RequestParamsService,
    private pagedataApiService: PagedataApiService, // エンタープライズ開発の段階で最新版との差異が出たためコメントアウト // ステージングから検証系に反映する際にコメントアウトを外す！忘れない！絶対！ // private storageService: StorageService
    private metaDataController: MetaDataController,
    private storageService: StorageService,
    public accountService: AccountService,
    public windowService: WindowService
  ) {
    this.parseRequestParams();
    this.checkTransition();
    this.isDebuggIOS16_3 = this.getData(this.IS_DEBUGG_IOS16_3) as boolean;
  }

  private parseRequestParams() {
    const params = this.requestParamsService.getRequestParams();
    // 店舗詳細サロン名がセットされている場合、モーダル表示店舗名に店舗詳細サロン名をセットする
    if (params.shopDetail != null) {
      this.openModalSalonName = params.shopDetail;
    }
    // actionパラメータがセットされている場合
    if (params.action != null) {
      this.action = params.action;
    }
    // ページ名がある場合は、ページ名を上書き
    if (params.pageName != null) {
      this.pageName = params.pageName;
    }
    // モーダルページ名がある場合は、モーダルページ名を上書き
    if (params.page != null) {
      this.openModalPageName = params.page;
    } else {
      this.openModalPageName = 'home';
    }
    if (params.isCompanyForNonCustomDomain && environment.local) {
      this.isCompanyForLocal = true;
    }
    this.viewMode = params.viewMode;
    this.isReload = params.isReload;
    this.isPreview = params.isPreview;
    this.paramKey = params.key;
    console.log('isPreview:' + this.isPreview);
  }

  async checkShopdetailRelSalon(shopDetail: string) {
    await this.accountService.fetchAndSetSalonFromOwnDomain();
    const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
    const checkData = await this.pagedataApiService.getCheckShopdetailRelSalon(accountData.accountName, shopDetail);
    return checkData['isShopdetailRelSalon'];
  }

  async checkShopdetailRelSalonForUrl(companyName: string, shopDetail: string) {
    const checkData = await this.pagedataApiService.getCheckShopdetailRelSalon(companyName, shopDetail);
    return checkData['isShopdetailRelSalon'];
  }

  async getDataForLink(salonId: number) {
    let resultData = {};
    const salonData = await this.pagedataApiService.getCheckTransitionDataById(salonId);
    resultData['salonData'] = salonData ? salonData['salonData'] : null;
    resultData['dataHost'] = await this.pagedataApiService.getDataHost();
    resultData['local'] = environment.local;
    return resultData;
  }

  /**
   *  画面遷移チェック
   */
  async checkTransition() {
    const params = this.requestParamsService.getRequestParams();
    await this.accountService.fetchAndSetSalonFromOwnDomain();
    const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
    const checkTransitionData = await this.pagedataApiService.getCheckTransitionData(
      accountData.accountName,
      accountData.isCompany
    );
    // 「アプリだったらリダイレクトしない」の判断
    // this.getIsStandAloneMode()はkey付きURLで強制trueになるので使えない
    this.isPwaApp = (('standalone' in window.navigator && window.navigator['standalone']) ||
      window.matchMedia('(display-mode: standalone)').matches) as boolean;
    // staging（検証用）、production（商用）を取得
    this.pwaEnv = await this.pagedataApiService.getPwaEnv();
    // 公開ページの場合
    if (!this.isPreview) {
      // ▼▼▼▼▼404エラーへ飛ばす▼▼▼▼▼
      if (
        checkTransitionData.salonData.logicalDeleteFlg // 論理削除済み
      ) {
        // CSの404エラー画面に遷移する
        location.href = checkTransitionData.redirectErrorPage404URL;
      }
      // ▼▼▼▼▼今すぐ予約へ飛ばす▼▼▼▼▼
      else if (
        checkTransitionData.salonData.currentPage != 'initial_complete' || // 初期設定が完了していない場合
        // || checkTransitionData.salonData.publishedDate == null              // 初期設定完了後に公開ボタンを押していない場合　→　この場合は非公開メッセージを出すように変更（PWAN-706）
        (checkTransitionData.salonData.isPublic == false && params.key != null) // 非公開かつ予約用パラメータを持つURLの場合
      ) {
        // リダイレクト先に遷移する
        location.href = checkTransitionData.redirectURL;
      }
      // ベーシックURL向けの60日アラート処理
      // 本部ドメインで店舗詳細モーダルを開く場合は、checkTransitionData.salonData.appEnabledは5（本部）になるので、スルーできる
      // 引っかかるのは、店舗ドメインで開いた場合のみ
      else if (checkTransitionData.salonData.appEnabled === 6 && !accountData.isCompany) {
        let nowDate = new Date(Date.now());
        let dateAfter60days = checkTransitionData.salonData.dateAfter60days;
        if (!dateAfter60days || typeof dateAfter60days === 'undefined') {
          // 本部がPWAエンタープライズ本部を契約したが、まだ本部PWAを一度も公開していない場合
          // PWAベーシックのURLのままアプリを表示させるので、何もしない
        } else {
          // 遷移先のURLを取得
          let companySalonData: SalonData = await this.getCompanySalonDataById(
            Number(checkTransitionData.salonData.id)
          );
          let newURL = 'https://';
          if (companySalonData.ownDomain != null) {
            newURL += companySalonData.ownDomain;
          } else {
            const domain =
              this.pwaEnv === 'production'
                ? AppComponent.PRODUCTION_COMPANY_DOMAIN
                : AppComponent.STAGING_COMPANY_DOMAIN;
            newURL += companySalonData.accountName + domain;
          }
          // アプリの告知が「本部」の場合は本部のページを開き、「店舗」の場合は店舗詳細のページを開く
          if (companySalonData.announcementCompanyOrSalon === this.ANNOUNCEMENT_SALON) {
            newURL += '/home?shopDetail=' + checkTransitionData.salonData.accountName;
          }

          if (!this.isPwaApp) {
            // ブラウザの場合、アクセス期限に関わらず本部ドメインの店舗詳細モーダルにリダイレクトする
            location.href = newURL;
          } else {
            let date = new Date(dateAfter60days);
            this.newUrlForEnterprise = newURL;
            // アプリの場合、リダイレクトするとアプリ内ブラウザで開いてしまうので、メッセージを表示する
            if (nowDate > date) {
              this.isInaccessible = true;
            } else {
              this.isInaccessibleSoon = true;
            }
          }
        }
      }
      // カスタムドメインのリダイレクト
      if (!this.isPwaApp && accountData.isRedirect && checkTransitionData.salonData.ownDomain !== null) {
        location.href = 'https://' + checkTransitionData.salonData.ownDomain + location.pathname + location.search;
      }
    }

    // 新しいバージョンをリリースしても初回アクセスはSWが先にキャッシュを返す場合があるので、初回は強制リロードさせる
    // →リロードは不要になったので削除
    const appVersionData = await this.pagedataApiService.getAppVersion();
    this.setData(LocalStorageData.APP_VER, appVersionData);
    // 独自ドメインのリストを取得
    const tmpOwnDomainList = await this.pagedataApiService.getOwnDomainList();
    this.ownDomainList = tmpOwnDomainList['owndomains'];
  }

  /**
   *  データを端末に保存
   */
  setData(name: string, data: any) {
    try {
      localStorage[name] = JSON.stringify(data);
    } catch (e) {
      // ローカルストレージlocalstorageの容量がいっぱいで、データのセットに失敗する場合
      // importantLocalStorage以外のデータを削除して保存させる
      if (e instanceof DOMException && e.name === 'QuotaExceededError') {
        // importantLocalStorageのデータは必ず保存させる
        if (LocalStorageData.IMPORTANT_DATA.includes(name)) {
          let removeItemList = [];
          for (let i = 0; i < localStorage.length; i++) {
            let key = localStorage.key(i);
            if (!LocalStorageData.IMPORTANT_DATA.includes(key)) {
              removeItemList.push(key);
            }
          }
          // 絶対保存しないといけないデータ以外は削除
          for (let i = 0; i < removeItemList.length; i++) {
            localStorage.removeItem(removeItemList[i]);
          }
          localStorage[name] = JSON.stringify(data);
        } else {
          // importantLocalStorageにないデータは保存できなくても良いのでスルーする
        }
      } else {
        // localStrage容量溢れ以外のエラー。今のところここには到達しない想定
        console.log('エラー' + e);
      }
    }
  }

  /**
   *  データを端末から取得
   */
  getData(name: string) {
    const result = localStorage[name];
    // nullはjsonにパースできない
    if (result != null) {
      return JSON.parse(result);
    } else {
      return null;
    }
  }

  checkDisplayOfAppEnabled(salonData) {
    let notDisplayPageList = [];
    if (salonData['appEnabled'] == 5) {
      notDisplayPageList = ['staff', 'staffDetail'];
      if (notDisplayPageList.indexOf(this.pageName.replace('/', '')) >= 0) {
        return false;
      }
    }
    return true;
  }

  /**
   * ローカルデータとindexedDBを全て削除
   */
  allClearData() {
    localStorage.clear();
    this.storageService.delete();
  }

  /**
   *  データを端末から取得
   */
  getDataPromise(name: string): Promise<any> {
    return new Promise((resolve) => {
      const result = localStorage[name];
      if (result != null) {
        resolve(JSON.parse(result));
      } else {
        resolve(null);
      }
    });
  }

  // indexedDbに保存
  async setTokenToIndexedDb(_token: string) {
    const data: UserData = { id: 0, token: _token };
    await this.storageService.setUserData(data);
  }

  // indexedDbから取得
  async getTokenFromIndexedDb() {
    const data = await this.storageService.getUserData();
    if (data) {
      return data.token;
    } else {
      return null;
    }
  }

  /**
   *  プレビューの判定を返却
   */
  getPreview() {
    return this.isPreview;
  }

  /**
   *  全画面のデータを先読みする
   */
  async preload() {
    const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
    console.log('preload:' + accountData.accountName + ':' + this.isPreview);

    this.getLayoutData();
    this.getMenuPageData();
    this.getStaffPageData().then((staffPageData) => {
      for (let staff of staffPageData['staffPerson']) {
        this.getStaffDetailPageData(accountData.accountName, staff.id);
      }
    });
    this.getSalonPageData();

    let isAllOrder = '1';
    this.getGalleryPageData(0, 0, 9, isAllOrder, null);
    this.getNewsPageData().then((pageData) => {
      for (let news of pageData['news']) {
        this.getNewsDetailPageData(accountData.accountName, news.id);
      }
    });
    this.getCampaignPageData();
    this.getThemeData();
    this.getSalonData();
  }

  async getThemeData(): Promise<ThemeData> {
    if (navigator.onLine) {
      await this.accountService.fetchAndSetSalonFromOwnDomain();
      const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
      if (this.themeData == null) {
        const pageData = await this.pagedataApiService.getThemeData(
          accountData.accountName,
          this.isPreview,
          accountData.isCompany
        );
        this.themeData = pageData;
        if (pageData != null) {
          this.setData(LocalStorageData.THEME, pageData);
        }
        return pageData;
      } else {
        return this.themeData;
      }
    } else {
      return this.getData(LocalStorageData.THEME);
    }
  }

  // エンタープライズの店舗詳細表示用のテーマ取得
  async getThemeDataForEnterpriseShop(shopName: string): Promise<ThemeData> {
    // 引数で渡されたshopNameを格納
    var searchShopName = shopName;
    // shopNameから店舗データを取得
    const checkTransitionData = await this.pagedataApiService.getCheckTransitionData(shopName, false);
    let shopData = checkTransitionData.salonData;
    let isUseCompanyData = false;
    // 店舗情報から、エンタープライズの店舗詳細かつアドバンス利用していない店舗の場合（本部データのテーマを取得する必要がある場合）
    if (shopData.companyId != null && shopData.appEnabled != 4) {
      // 店舗情報のcompanyIdで本部データを取得し、本部のaccountNameをテーマ取得用の引数として使用する
      const companyCheckTransitionData = await this.pagedataApiService.getCheckTransitionDataById(shopData.companyId);
      searchShopName = companyCheckTransitionData.salonData.accountName;
      isUseCompanyData = true;
    }

    if (navigator.onLine) {
      if (this.themeDataEnterpriseShopList[searchShopName] == null) {
        const pageData = await this.pagedataApiService.getThemeData(searchShopName, this.isPreview, isUseCompanyData);
        // アドバンス利用している店舗の場合：各店舗のaccountNameでテーマを格納
        // エンタープライズの店舗詳細の場合：本部のaccountNameでテーマを格納（別店舗詳細がテーマ取得してきたときはすでに格納されているので重複取得しない）
        this.themeDataEnterpriseShopList[searchShopName] = pageData;
        if (pageData != null) {
          this.setData(LocalStorageData.THEME, pageData);
        }
        return pageData;
      } else {
        // アドバンス利用している店舗の場合：各店舗のaccountNameで格納されたテーマを取得
        // エンタープライズの店舗詳細の場合：本部のaccountNameで格納されたテーマを格納
        return this.themeDataEnterpriseShopList[searchShopName];
      }
    } else {
      return this.getData(LocalStorageData.THEME); // 今通ってないから無視
    }
  }

  public setThemeForShopDetailModal(data: ThemeData) {
    for (let name in this.shopDetailModalStyles) {
      this.shopDetailModalStyles[name] = false;
    }
    const fontStyle = data.font;
    this.shopDetailModalStyles[fontStyle] = true;
    const headingStyle = data.headingStyle;
    this.shopDetailModalStyles[headingStyle] = true;
    const lighten20 = lightenColor(data.color.accentColor, 20);
    const lighten50 = lightenColor(data.color.accentColor, 50);
    const $ = (el) => document.querySelector(el);
    $(':root').style.setProperty('--accent-color-modal', data.color.accentColor);
    $(':root').style.setProperty('--accent-color-rgb-modal', HEXtoRGB(data.color.accentColor));
    $(':root').style.setProperty('--functional-color-modal', data.color.functionalColor);
    $(':root').style.setProperty('--functional-color-rgb-modal', HEXtoRGB(data.color.functionalColor));
    $(':root').style.setProperty('--lighten-20-color-modal', `rgb(${lighten20})`);
    $(':root').style.setProperty('--lighten-50-color-modal', `rgb(${lighten50})`);
    $(':root').style.setProperty('--base-color-modal', data.color.baseColor);
    $(':root').style.setProperty('--base-color-rgb-modal', HEXtoRGB(data.color.baseColor));
    $(':root').style.setProperty('--attention-color-modal', data.color.attentionColor);
    $(':root').style.setProperty('--attention-color-rgb-modal', HEXtoRGB(data.color.attentionColor));

    this.metaDataController.updateThemeColor(data.color.baseColor);

    this.loadFonts(data.font);
    return this.shopDetailModalStyles;
  }

  public setThemeForShopListMapModal(data: ThemeData) {
    for (let name in this.shopListMapModalStyles) {
      this.shopListMapModalStyles[name] = false;
    }
    const fontStyle = data.font;
    this.shopListMapModalStyles[fontStyle] = true;
    const headingStyle = data.headingStyle;
    this.shopListMapModalStyles[headingStyle] = true;
    this.loadFonts(data.font);
    return this.shopListMapModalStyles;
  }

  // Webフォントロード
  loadFonts(font: string) {
    switch (font) {
      case 'theme0101':
        WebFont.load({
          google: {
            families: ['Noto Serif JP']
          }
        });
        WebFont.load({
          google: {
            families: ['Nanum Myeongjo']
          }
        });
        break;
      case 'theme0102':
        WebFont.load({
          google: {
            families: ['Noto Sans JP']
          }
        });
        WebFont.load({
          google: {
            families: ['Arvo']
          }
        });
        break;
      case 'theme0103':
        WebFont.load({
          google: {
            families: ['Noto Sans JP']
          }
        });
        break;
      case 'theme0104':
        WebFont.load({
          google: {
            families: ['Noto Sans JP']
          }
        });
        WebFont.load({
          google: {
            families: ['Noto Serif']
          }
        });
        WebFont.load({
          google: {
            families: ['Noto Serif JP']
          }
        });
        break;
      case 'theme0105':
        WebFont.load({
          google: {
            families: ['Noto Sans JP']
          }
        });
        WebFont.load({
          google: {
            families: ['Arvo']
          }
        });
        break;
      case 'theme0201':
        WebFont.load({
          google: {
            families: ['Noto Sans JP']
          }
        });
        WebFont.load({
          google: {
            families: ['Amatic SC']
          }
        });
        break;
      case 'theme0202':
        WebFont.load({
          google: {
            families: ['Noto Sans JP']
          }
        });
        WebFont.load({
          google: {
            families: ['Caveat']
          }
        });
        break;
      case 'theme0203':
        WebFont.load({
          google: {
            families: ['Noto Sans JP']
          }
        });
        WebFont.load({
          google: {
            families: ['Amatic SC']
          }
        });
        break;
      case 'theme0204':
        WebFont.load({
          google: {
            families: ['Noto Sans JP']
          }
        });
        WebFont.load({
          google: {
            families: ['Caveat']
          }
        });
        break;
      case 'theme0205':
        WebFont.load({
          google: {
            families: ['Noto Sans JP']
          }
        });
        WebFont.load({
          google: {
            families: ['Delius Swash Caps']
          }
        });
        break;
      case 'theme0206':
        WebFont.load({
          google: {
            families: ['Noto Sans JP']
          }
        });
        WebFont.load({
          google: {
            families: ['Courgette']
          }
        });
        break;
      case 'theme0301':
        WebFont.load({
          google: {
            families: ['Noto Serif JP']
          }
        });
        WebFont.load({
          google: {
            families: ['Marcellus']
          }
        });
        break;
      case 'theme0302':
        WebFont.load({
          google: {
            families: ['Noto Sans JP']
          }
        });
        WebFont.load({
          google: {
            families: ['Josefin Sans:400']
          }
        });
        break;
      case 'theme0303':
        WebFont.load({
          google: {
            families: ['Noto Sans JP']
          }
        });
        WebFont.load({
          google: {
            families: ['Josefin Sans:300']
          }
        });
        break;
      case 'theme0304':
        WebFont.load({
          google: {
            families: ['Noto Serif JP']
          }
        });
        WebFont.load({
          google: {
            families: ['Abril Fatface']
          }
        });
        break;
      case 'theme0305':
        WebFont.load({
          google: {
            families: ['Noto Sans JP']
          }
        });
        WebFont.load({
          google: {
            families: ['Josefin Sans']
          }
        });
        break;
      case 'theme0306':
        WebFont.load({
          google: {
            families: ['Noto Sans JP']
          }
        });
        WebFont.load({
          google: {
            families: ['Staatliches']
          }
        });
        break;
      case 'theme0307':
        WebFont.load({
          google: {
            families: ['Noto Sans JP']
          }
        });
        WebFont.load({
          google: {
            families: ['Comfortaa']
          }
        });
        break;
    }
  }

  public resetThemeForShopDetailModal() {
    for (let name in this.shopDetailModalStyles) {
      this.shopDetailModalStyles[name] = false;
    }
    const $ = (el) => document.querySelector(el);
    $(':root').style.setProperty('--accent-color-modal', $(':root').style.getPropertyValue('--accent-color'));
    $(':root').style.setProperty('--functional-color-modal', $(':root').style.getPropertyValue('--functional-color'));
    $(':root').style.setProperty('--lighten-20-color-modal', $(':root').style.getPropertyValue('--lighten-20-color'));
    $(':root').style.setProperty('--lighten-50-color-modal', $(':root').style.getPropertyValue('--lighten-50-color'));
    $(':root').style.setProperty('--base-color-modal', $(':root').style.getPropertyValue('--base-color'));
    $(':root').style.setProperty('--base-color-rgb-modal', $(':root').style.getPropertyValue('--base-color-rgb'));
    $(':root').style.setProperty('--attention-color-modal', $(':root').style.getPropertyValue('--attention-color'));
    this.metaDataController.updateThemeColor($(':root').style.getPropertyValue('--base-color'));
  }

  public resetThemeForShopListMapModal() {
    for (let name in this.shopListMapModalStyles) {
      this.shopListMapModalStyles[name] = false;
    }
  }

  async getLayoutData(shopDetailName = null): Promise<LayoutData> {
    if (navigator.onLine) {
      await this.accountService.fetchAndSetSalonFromOwnDomain();
      const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
      let searchSalonName = accountData.accountName;
      let isCompany = accountData.isCompany;
      if (shopDetailName != null) {
        searchSalonName = shopDetailName;
        isCompany = false;
      }
      const pageData = await this.pagedataApiService.getLayoutData(searchSalonName, this.isPreview, isCompany);
      if (pageData != null) {
        this.setData(LocalStorageData.LAYOUT, pageData);
      }
      return pageData;
    } else {
      return this.getData(LocalStorageData.LAYOUT);
    }
  }

  async getHomePageData(shopDetailName = null): Promise<HomePageData> {
    if (navigator.onLine) {
      await this.accountService.fetchAndSetSalonFromOwnDomain();
      const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
      let searchSalonName = accountData.accountName;
      let isCompany = accountData.isCompany;
      if (shopDetailName != null) {
        searchSalonName = shopDetailName;
        isCompany = false;
      }
      const pageData = await this.pagedataApiService.getHomePageData(searchSalonName, this.isPreview, isCompany);
      if (pageData != null) {
        this.setData(LocalStorageData.HOME, pageData);
      }
      return pageData;
    } else {
      return this.getData(LocalStorageData.HOME);
    }
  }

  async getMenuPageData(shopDetailName: string = null, setPreview?: boolean): Promise<MenuPageData> {
    if (navigator.onLine) {
      await this.accountService.fetchAndSetSalonFromOwnDomain();
      const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
      if (typeof setPreview === 'undefined') setPreview = this.isPreview;
      let searchSalonName = accountData.accountName;
      let isCompany = accountData.isCompany;
      if (shopDetailName != null) {
        searchSalonName = shopDetailName;
        isCompany = false;
      }
      const pageData = await this.pagedataApiService.getMenuPageData(searchSalonName, setPreview, isCompany);
      if (pageData != null) {
        this.setData(LocalStorageData.MENU, pageData);
      }
      return pageData;
    } else {
      return this.getData(LocalStorageData.MENU);
    }
  }

  async getStaffPageData(shopDetailName: string = null, setPreview?: boolean): Promise<StaffPageData> {
    if (navigator.onLine) {
      await this.accountService.fetchAndSetSalonFromOwnDomain();
      const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
      if (typeof setPreview === 'undefined') setPreview = this.isPreview;
      let searchSalonName = accountData.accountName;
      let isCompany = accountData.isCompany;
      if (shopDetailName != null) {
        searchSalonName = shopDetailName;
        isCompany = false;
      }
      const pageData = await this.pagedataApiService.getStaffPageData(searchSalonName, setPreview, isCompany);
      if (pageData != null) {
        this.setData(LocalStorageData.STAFF, pageData);
      }
      return pageData;
    } else {
      return this.getData(LocalStorageData.STAFF);
    }
  }

  async getStaffDetailPageData(
    shopDetailName: string = null,
    staffId: number,
    setPreview?: boolean
  ): Promise<StaffDetailPageData> {
    let dataId = LocalStorageData.STAFFDETAIL + staffId;
    if (navigator.onLine) {
      await this.accountService.fetchAndSetSalonFromOwnDomain();
      const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
      if (typeof setPreview === 'undefined') setPreview = this.isPreview;
      let searchSalonName = accountData.accountName;
      let isCompany = accountData.isCompany;
      if (shopDetailName != null) {
        searchSalonName = shopDetailName;
        isCompany = false;
      }
      const pageData = await this.pagedataApiService.getStaffDetailPageData(
        searchSalonName,
        setPreview,
        staffId,
        isCompany
      );
      if (pageData != null) {
        this.setData(dataId, pageData);
      }
      return pageData;
    } else {
      return this.getData(dataId);
    }
  }

  async getSalonPageData(shopDetailName: string = null, setPreview?: boolean): Promise<SalonPageData> {
    if (navigator.onLine) {
      await this.accountService.fetchAndSetSalonFromOwnDomain();
      const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
      if (typeof setPreview === 'undefined') setPreview = this.isPreview;
      let searchSalonName = accountData.accountName;
      let isCompany = accountData.isCompany;
      if (shopDetailName != null) {
        searchSalonName = shopDetailName;
        isCompany = false;
      }
      const pageData = await this.pagedataApiService.getSalonPageData(searchSalonName, setPreview, isCompany);
      if (pageData != null) {
        this.setData(LocalStorageData.SALON, pageData);
      }
      return pageData;
    } else {
      return this.getData(LocalStorageData.SALON);
    }
  }

  async getGalleryPageData(
    category: number,
    offset: number,
    num: number,
    isAllOrder: string,
    brandId: number,
    shopDetailName: string = null,
    setPreview?: boolean
  ): Promise<GalleryPageData> {
    if (navigator.onLine) {
      await this.accountService.fetchAndSetSalonFromOwnDomain();
      const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
      if (typeof setPreview === 'undefined') setPreview = this.isPreview;
      let searchSalonName = accountData.accountName;
      let isCompany = accountData.isCompany;
      if (shopDetailName != null) {
        searchSalonName = shopDetailName;
        isCompany = false;
      }
      const pageData = await this.pagedataApiService.getGalleryPageData(
        searchSalonName,
        setPreview,
        category,
        offset,
        num,
        isAllOrder,
        isCompany,
        brandId
      );
      if (pageData != null) {
        this.setData(LocalStorageData.GALLERY, pageData);
      }
      return pageData;
    } else {
      return this.getData(LocalStorageData.GALLERY);
    }
  }

  async getCampaignPageData(shopDetailName: string = null, setPreview?: boolean): Promise<CampaignPageData> {
    if (navigator.onLine) {
      await this.accountService.fetchAndSetSalonFromOwnDomain();
      const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
      if (typeof setPreview === 'undefined') setPreview = this.isPreview;
      let searchSalonName = accountData.accountName;
      let isCompany = accountData.isCompany;
      if (shopDetailName != null) {
        searchSalonName = shopDetailName;
        isCompany = false;
      }
      const pageData = await this.pagedataApiService.getCampaignPageData(searchSalonName, setPreview, isCompany);
      if (pageData != null) {
        this.setData(LocalStorageData.CAMPAIGN, pageData);
      }
      return pageData;
    } else {
      return this.getData(LocalStorageData.CAMPAIGN);
    }
  }

  async getNewsPageData(shopDetailName: string = null, setPreview?: boolean): Promise<NewsPageData> {
    if (navigator.onLine) {
      await this.accountService.fetchAndSetSalonFromOwnDomain();
      const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
      if (typeof setPreview === 'undefined') setPreview = this.isPreview;
      let searchSalonName = accountData.accountName;
      let isCompany = accountData.isCompany;
      if (shopDetailName != null) {
        searchSalonName = shopDetailName;
        isCompany = false;
      }
      const pageData = await this.pagedataApiService.getNewsPageData(searchSalonName, setPreview, isCompany);
      if (pageData != null) {
        this.setData(LocalStorageData.NEWS, pageData);
      }
      return pageData;
    } else {
      return this.getData(LocalStorageData.NEWS);
    }
  }

  async getNewsDetailPageData(
    shopDetailName: string = null,
    newsId: number,
    setPreview?: boolean
  ): Promise<NewsDetailPageData> {
    const dataId = LocalStorageData.NEWSDETAIL + newsId;
    if (navigator.onLine) {
      await this.accountService.fetchAndSetSalonFromOwnDomain();
      const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
      if (typeof setPreview === 'undefined') setPreview = this.isPreview;
      let searchSalonName = accountData.accountName;
      let isCompany = accountData.isCompany;
      if (shopDetailName != null) {
        searchSalonName = shopDetailName;
        isCompany = false;
      }
      const pageData = await this.pagedataApiService.getNewsDetailPageData(
        searchSalonName,
        setPreview,
        newsId,
        isCompany
      );
      if (pageData != null) {
        this.setData(dataId, pageData);
      }
      return pageData;
    } else {
      return this.getData(dataId);
    }
  }

  // これ多分使ってない
  async getGalleryData(category: number, offset: number, num: number, isAllOrder: string): Promise<GalleryPageData> {
    if (navigator.onLine) {
      await this.accountService.fetchAndSetSalonFromOwnDomain();
      const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
      const pageData = await this.pagedataApiService.getGalleryData(
        accountData.accountName,
        this.isPreview,
        category,
        offset,
        num,
        isAllOrder,
        accountData.isCompany
      );
      if (pageData != null) {
        this.setData(LocalStorageData.GALLERY, pageData);
      }
      return pageData;
    } else {
      return this.getData(LocalStorageData.GALLERY);
    }
  }

  // これも多分使ってない
  async getGalleryDataByStaff(staffId: number, offset: number, num: number): Promise<GallerySummaryData> {
    if (navigator.onLine) {
      await this.accountService.fetchAndSetSalonFromOwnDomain();
      const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
      const pageData = await this.pagedataApiService.getGalleryDataByStaff(
        accountData.accountName,
        this.isPreview,
        staffId,
        offset,
        num,
        accountData.isCompany
      );
      return pageData;
    } else {
      return null;
    }
  }

  async getFreePageData(shopDetailName: string = null, pageName: string, setPreview?: boolean): Promise<FreePageData> {
    if (navigator.onLine) {
      await this.accountService.fetchAndSetSalonFromOwnDomain();
      const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
      if (typeof setPreview === 'undefined') setPreview = this.isPreview;
      let searchSalonName = accountData.accountName;
      let isCompany = accountData.isCompany;
      if (shopDetailName != null) {
        searchSalonName = shopDetailName;
        isCompany = false;
      }
      const pageData = await this.pagedataApiService.getFreePageData(searchSalonName, setPreview, pageName, isCompany);
      if (pageData != null) {
        this.setData(LocalStorageData.FREE, pageData);
      }
      return pageData;
    } else {
      return this.getData(LocalStorageData.FREE);
    }
  }

  async getShopListPageData(setPreview?: boolean): Promise<ShopListData> {
    if (navigator.onLine) {
      await this.accountService.fetchAndSetSalonFromOwnDomain();
      const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
      if (typeof setPreview === 'undefined') setPreview = this.isPreview;
      const pageData = await this.pagedataApiService.getShopListData(
        accountData.accountName,
        setPreview,
        accountData.isCompany
      );
      if (pageData != null) {
        this.setData(LocalStorageData.SHOPLIST, pageData);
      }
      return pageData;
    } else {
      return this.getData(LocalStorageData.SHOPLIST);
    }
  }

  public getRouteParams(): any {
    return { p: this.isPreview ? '1' : null, key: this.paramKey };
  }

  getRequestParams() {
    return new URLSearchParams(window.location.search.slice(1));
  }

  getScope() {
    return window.location.origin;
  }

  getStartUrl() {
    if (this.isPreview) {
      return window.location.origin + '/home?p=1';
    }
    return window.location.origin + '/home';
  }

  async setUpdatedDate() {
    if (navigator.onLine) {
      dayjs.extend(utc);
      await this.setData(LocalStorageData.UPDATED_DATE, dayjs(new Date()).utc().format('YYYY-MM-DD HH:mm:ss'));
    } else {
      this.setData(LocalStorageData.UPDATED_DATE, null);
    }
  }

  async isNeedreload() {
    if (!navigator.onLine) {
      return false;
    }
    dayjs.extend(utc);
    // lodcalStorageの「updatedDate」がnullということは、アクセス時にオフラインだったので、ここでリロードさせる
    if (this.getData(LocalStorageData.UPDATED_DATE) == null) {
      return true;
    }
    var updatedDate = Date.parse(this.getData(LocalStorageData.UPDATED_DATE).replace(/-/g, '/'));
    var nowDate = Date.parse(dayjs(new Date()).utc().format('YYYY-MM-DD HH:mm:ss').replace(/-/g, '/'));
    if ((nowDate - updatedDate) / (1000 * 60) > 120) {
      console.log('publishedDate check');
      await this.accountService.fetchAndSetSalonFromOwnDomain();
      const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
      const publishedDate = await this.pagedataApiService.getPublishedDate(
        accountData.accountName,
        accountData.isCompany
      );
      this.setData(LocalStorageData.UPDATED_DATE, dayjs(new Date()).utc().format('YYYY-MM-DD HH:mm:ss'));
      if (Date.parse(publishedDate.replace(/-/g, '/')) > updatedDate) {
        return true;
      }
    }
    return false;
  }

  async getSalonData(shopDetailName: string = null): Promise<SalonData> {
    if (navigator.onLine) {
      if (shopDetailName == null) {
        if (this.salonData == null) {
          await this.accountService.fetchAndSetSalonFromOwnDomain();
          const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
          const checkTransitionData = await this.pagedataApiService.getCheckTransitionData(
            accountData.accountName,
            accountData.isCompany
          );
          const pageData = checkTransitionData.salonData;
          this.salonData = pageData;
          if (pageData != null) {
            this.setData(LocalStorageData.SALON_DATA, pageData);
          }
          return pageData;
        } else {
          return this.salonData;
        }
      } else {
        const checkTransitionData = await this.pagedataApiService.getCheckTransitionData(shopDetailName, false);
        const pageData = checkTransitionData.salonData;
        return pageData;
      }
    } else {
      return this.getData(LocalStorageData.SALON_DATA);
    }
  }

  async getCompanySalonDataById(salonId: number): Promise<SalonData> {
    if (navigator.onLine) {
      const pageData = await this.pagedataApiService.getCompanySalonDataById(salonId);
      return pageData;
    } else {
      return null;
    }
  }

  async getHomeDataForOG(shopDetailName: string = null): Promise<HomePageData> {
    if (navigator.onLine) {
      await this.accountService.fetchAndSetSalonFromOwnDomain();
      const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
      let searchSalonName = accountData.accountName;
      let isCompany = accountData.isCompany;
      if (shopDetailName != null) {
        searchSalonName = shopDetailName;
        isCompany = false;
      }
      const pageData = await this.pagedataApiService.getHomeDataForOG(searchSalonName, isCompany);
      return pageData['home'];
    } else {
      // ここにくることはないはず
      return null;
    }
  }

  public getIsStandAloneMode(): boolean {
    if (this.isStandAloneMode === null) {
      // セットされていない場合は、判定を行う
      if (this.paramKey != null) {
        // keyがある場合はスタンドアロンと同じ動き
        this.isStandAloneMode = true;
      } else {
        // keyがない場合は正しくスタンドアロンを判定する
        this.isStandAloneMode =
          ('standalone' in window.navigator && window.navigator['standalone'] === 'boolean') ||
          (window.matchMedia('(display-mode: standalone)').matches as boolean);
      }
    }
    // ローカルの場合は設定内容から判定
    if (environment.local && !this.paramKey) {
      return environment.isStandAlone;
    }
    return this.isStandAloneMode;
  }

  /**
   * 端末の情報を更新する
   */
  public async setDeviceInfo(): Promise<void> {
    const userAgent = navigator.userAgent;
    const ua = userAgent.toLowerCase();
    let os = { name: '', version: '' };
    let browser = { name: '', version: '' };
    this.isiPad = ua.indexOf('ipad') > -1 || (ua.indexOf('macintosh') > -1 && 'ontouchend' in document);
    if (navigator.userAgentData) {
      // User-Agent Client Hintsが利用可能な場合
      const uach = await navigator.userAgentData.getHighEntropyValues([
        'platform',
        'platformVersion',
        'architecture',
        'model',
        'uaFullVersion'
      ]);

      // PC ChromeでUAをiPhoneにするとuachから値をとれない
      if (uach && uach.brands && uach.brands.length > 0) {
        this.modelName = uach.model;
        this.osVersion = uach.platformVersion;
        this.osMajorVersion = parseInt(this.osVersion);

        os.name = uach.platform;
        // brandsからブラウザを抽出
        for (let brandInfo of uach.brands) {
          if (brandInfo.brand.indexOf('Chrome') !== -1) {
            browser.name = brandInfo.brand;
            browser.version = brandInfo.version;
            break;
          } else if (brandInfo.brand === 'Samsung Browser') {
            browser.name = brandInfo.brand;
            browser.version = brandInfo.version;
            break;
          } else if (brandInfo.brand.indexOf('Safari') !== -1) {
            browser.name = brandInfo.brand;
            browser.version = brandInfo.version;
            break;
          }
        }
        return this.setOsInfo(ua, os, browser);
      }
    }
    // ua-parser-jsを利用
    const uaParser = new UAParser(userAgent);
    const device = uaParser.getDevice();
    if (!!device && device.model) {
      this.modelName = device.model;
    }
    os = uaParser.getOS();
    if (!!os) {
      if (os.version) {
        this.osVersion = os.version;
      }
    }
    browser = uaParser.getBrowser();
    return this.setOsInfo(ua, os, browser);
  }

  private setOsInfo(ua, os, browser) {
    if (os.name && (os.name === this.PLARTFORM_IOS || os.name === this.PLATFORM_ANDROID)) {
      this.osName = os.name;
    } else if (this.isiPad) {
      this.osName = this.PLARTFORM_IOS;
      this.modelName = this.PLARTFORM_IPAD;
      let iPadOSver = ua.substr(ua.indexOf('version/'));
      this.osVersion = iPadOSver.substring(0, iPadOSver.indexOf(' ')).replace('version/', '');
    } else {
      this.osName = this.PLATFORM_PWA;
    }
    // アンドロイドかどうか
    if (this.osName === this.PLATFORM_ANDROID) {
      this.isAndroid = true;
    }
    // iOSかどうか
    if (this.osName === this.PLARTFORM_IOS) {
      this.isIos = true;
    }
    if (!!this.osVersion) {
      this.osMajorVersion = parseInt(this.osVersion);
    }

    // ブラウザチェック
    if (!!browser) {
      if (browser.name.indexOf('Chrome') !== -1) {
        this.isDeprecation = false;
      } else if (browser.name === 'Samsung Browser') {
        if (this.isAndroid) {
          if (Number(browser.version) < 13) {
            this.isOldsamsungbrowser = true;
          }
        }
        this.isDeprecation = false;
      } else if (browser.name.indexOf('Safari') !== -1) {
        this.isSafari = true;
      }
    }
  }

  public getIsAndroid(): boolean {
    return this.isAndroid;
  }

  public getIsDeprecation(): boolean {
    return this.isDeprecation;
  }

  public getIsOldsamsungbrowser(): boolean {
    return this.isOldsamsungbrowser;
  }

  public getIsIos(): boolean {
    return this.isIos;
  }

  public getIsipad(): boolean {
    return this.isiPad;
  }

  public getIsSafari(): boolean {
    return this.isSafari;
  }

  public getModelName(): string {
    return this.modelName;
  }

  public async getOsVersion(): Promise<string> {
    if (this.isDebuggIOS16_3) {
      return '16.3';
    }
    return this.osVersion;
  }

  public getOsName(): string {
    return this.osName;
  }

  public getOsMajorVersion(): number {
    return this.osMajorVersion;
  }

  public getIsCompany(): boolean {
    const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
    return accountData.isCompany;
  }

  // アカウント名から短縮店名を取得する（本部以外が検索対象）
  async getShortNameFromAccountName(accountName: string): Promise<string> {
    if (navigator.onLine) {
      const pageData = await this.pagedataApiService.getShortNameFromAccountName(accountName, this.isPreview);
      console.log(pageData);
      return pageData['shortName'];
    } else {
      return null;
    }
  }

  public setIsDebuggIOS16_3(isDebuggIOS16_3: boolean): void {
    this.setData(this.IS_DEBUGG_IOS16_3, isDebuggIOS16_3);
    this.isDebuggIOS16_3 = isDebuggIOS16_3;
  }

  public getIsDebuggIOS16_3(): boolean {
    return this.isDebuggIOS16_3;
  }

  public updatePrerenderReady(isFromShopdetailmodal) {
    // shopDetailモーダルが開いていない場合は、prerenderReadyをtrueにする
    // shopDetailモーダルが開いている場合は、ShopdetailmodalComponentでprerenderReadyをtrueにする
    if (this.windowService.isRendertron()) {
      const params = this.requestParamsService.getRequestParams();
      if (!isFromShopdetailmodal && params.shopDetail === null) {
        this.windowService.setPrerenderReady(true);
      }
      if (isFromShopdetailmodal && params.shopDetail !== null) {
        this.windowService.setPrerenderReady(true);
      }
    }
  }

  public getIsAnnouncementSalon(): boolean {
    return this.isAnnouncementSalon;
  }
  public setIsAnnouncementSalon(isAnnouncementSalon: boolean): void {
    this.isAnnouncementSalon = isAnnouncementSalon;
  }
}
