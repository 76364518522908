import { Injectable } from '@angular/core';
import { ViewChild } from '@angular/core';
import { PagedataService } from './pagedata.service';
import { IonNav, ModalController } from '@ionic/angular';
import { ShopdetailmodalComponent } from '../shopdetailmodal/shopdetailmodal.component';
import { UpdateGoogleAnalyticsService } from './updateGoogleAnalyticsService';
import { MetaDataController } from './metaDataController';
import { LayoutData } from '../interfaces/layoutData.interface';
import { initLayoutData, initSalonData } from '../initData/initData';

import { HomePage } from '../home/home.page';
import { MenuPage } from '../menu/menu.page';
import { StaffPage } from '../staff/staff.page';
import { StaffdetailPage } from '../staffdetail/staffdetail.page';
import { ShopPage } from '../shop/shop.page';
import { GalleryPage } from '../gallery/gallery.page';
import { NewsPage } from '../news/news.page';
import { NewsdetailPage } from '../newsdetail/newsdetail.page';
import { CampaignPage } from '../campaign/campaign.page';
import { FreePage } from '../free/free.page';
import { CookieService } from 'ngx-cookie-service';
import { CookieData } from '../pagedata';

@Injectable({
  providedIn: 'root'
})
export class ShopDetailModalService {
  public isShopDetailNav: boolean = false;
  public shopDetailNav: IonNav;
  public appHeader;
  public mobileFooter;
  public shopName: string = null;
  public layoutData: LayoutData = initLayoutData;
  private isShopDetailOpened: boolean = false;
  constructor(
    public pds: PagedataService,
    private modalController: ModalController,
    private updateGoogleAnalyticsService: UpdateGoogleAnalyticsService,
    private metaDataController: MetaDataController,
    private cookieService: CookieService
  ) {}

  // 店舗詳細モーダルを開く
  public async openShopDetailModal(
    shopName: string = '',
    pageName: string = '',
    requestParams: any = {},
    fnBrowserBack = function () {}
  ) {
    if (this.isShopDetailOpened) {
      return;
    }
    this.isShopDetailOpened = true;
    this.isShopDetailNav = true;
    this.shopName = shopName;
    this.layoutData = await this.pds.getLayoutData(this.shopName);

    // Cookieに店舗名をセット（isAnnouncementSalonに関わらず実行する。起動時に分岐させるため）
    if (!this.pds.isPwaApp) {
      this.cookieService.set(CookieData.INITIAL_APP_OPENED_SALON_TITLE, this.shopName, CookieData.VALID_DAYS, '/');
    }

    // モーダルを表示する
    // モーダルの背景要素を設定する（listMapModalが取得できない場合は、ion-pageを取得する）
    let presentingElement: HTMLElement;
    presentingElement = document.querySelector('#listMapModal');
    if (presentingElement === null) {
      presentingElement = document.querySelector('#ion-page');
    }
    let deviceClass = this.pds.getIsIos() ? '__Ios__' : '__noIos__';
    const modal = await this.modalController.create({
      id: 'shopDetailModal',
      component: ShopdetailmodalComponent,
      componentProps: { shopName: shopName, pageName: pageName, requestParams: requestParams },
      cssClass: ['card-modal', deviceClass],
      presentingElement: presentingElement,
      canDismiss: async function (data?: any, role?: string) {
        return role !== 'gesture';
      }
    });

    // モーダルを閉じた時の処理
    let modalResult: boolean | null = null;
    if (modal.presentingElement.classList.contains('card-modal') && modal.classList.contains('card-modal')) {
      // モーダル起動時：dismiss → モーダル側のionViewWillEnter → present
      await modal.dismiss().then(() => {});
      await modal.present().then(() => {
        // ブラウザバックイベントのブラウザバックコールバックイベントを外す
        console.log('!!!ShopDetailModalService present fnBrowserBack popstateを外す');
        window.removeEventListener('popstate', fnBrowserBack);
      });

      // モーダル停止時：モーダル側のionViewWillLeave → onWillDismiss → onDidDismiss
      await modal.onWillDismiss().then((result: { data: boolean; role: string }) => {});
      await modal.onDidDismiss().then((result: { data: boolean; role: string }) => {
        this.isShopDetailOpened = false;
        this.metaDataController.refreshTitleDescription();
        // ブラウザバックイベントとして、bind(this)したブラウザバックコールバックイベントをセット
        window.addEventListener('popstate', fnBrowserBack);
        this.isShopDetailNav = false;
        this.shopName = null;
      });
    } else {
      fnBrowserBack();
      await modal.present();
      await modal.onDidDismiss().then((result: { data: boolean; role: string }) => {
        this.isShopDetailOpened = false;
        this.metaDataController.refreshTitleDescription();
        this.isShopDetailNav = false;
        this.shopName = null;
      });
    }

    return modalResult;
  }

  // 店舗詳細モーダルを閉じる
  public closeShopDetailModal() {
    // Cookieの店舗名を消す（isAnnouncementSalonに関わらず実行する。起動時に分岐させるため）
    if (!this.pds.isPwaApp) {
      this.cookieService.delete(CookieData.INITIAL_APP_OPENED_SALON_TITLE, '/');
    }
    this.modalController.dismiss();
  }

  // 店舗詳細モーダル内のページ追加を行う
  public async pushPage(shopName, pageName, requestParams: any = {}, isHistoryPushState: boolean = true) {
    // 内部リンクとして空白（トップページ）がセットされている場合は、homeに変換する
    pageName = pageName === '' ? 'home' : pageName;
    let component = null;
    const componentParams = {
      shopName: shopName,
      isShopDetailNav: this.isShopDetailNav,
      requestParams: requestParams,
      pageName: pageName
    };
    // this.pageName = pageName;
    switch (pageName) {
      case 'home':
        component = HomePage;
        break;
      case 'menu':
        component = MenuPage;
        break;
      case 'staff':
        component = StaffPage;
        break;
      case 'staffdetail':
        component = StaffdetailPage;
        break;
      case 'shop':
        component = ShopPage;
        break;
      case 'gallery':
        component = GalleryPage;
        break;
      case 'news':
        component = NewsPage;
        break;
      case 'newsdetail':
        component = NewsdetailPage;
        break;
      case 'campaign':
        component = CampaignPage;
        break;
      default:
        component = FreePage;
        break;
    }

    // googleアナリティクス計上
    this.updateGoogleAnalyticsService.setShopLayoutData(this.layoutData);
    let UpdateGoogleAnalyticsShopName = '';
    if (this.layoutData === null || this.layoutData['shortName'] === null || typeof this.layoutData === 'undefined') {
      UpdateGoogleAnalyticsShopName = await this.pds.getShortNameFromAccountName(shopName);
    } else {
      UpdateGoogleAnalyticsShopName = this.layoutData['shortName'];
    }
    this.updateGoogleAnalyticsService.accruePageviews('/' + pageName, true, null, UpdateGoogleAnalyticsShopName);
    this.mobileFooter.refreshFooterBtn(pageName);
    // モーダルが存在する場合はページをプッシュ、存在しない場合はページを作成する
    const isActive = await this.shopDetailNav.getActive();
    if (typeof isActive !== 'undefined') {
      // this.isCanBack = true;
      // ページを追加する
      this.shopDetailNav.push(component, componentParams);
    } else {
      // 初期ページをセットする
      this.shopDetailNav.setRoot(component, componentParams);
    }
    if (isHistoryPushState) {
      // ページセットごとに空の履歴をセットする（ブラウザバックでモーダルを閉じたときに、元のページを再読込させないため）
      history.pushState(null, null, null);
    }
  }

  public async toHome(shopName) {
    this.pushPage(shopName, 'home', {}, false);
    const active = await this.shopDetailNav.getActive();
    this.shopDetailNav.removeIndex(active.nav.views.length - 1);
  }

  // 要素指定してスクロール
  public scrollToEle(offsetTop) {
    // ヘッダー高さを考慮してスクロール量を返す
    return offsetTop - this.appHeader.headerElement.nativeElement.clientHeight;
  }
}
