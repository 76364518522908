import { Injectable } from '@angular/core';

import { PagedataService } from '../services/pagedata.service';
import { ModalController } from '@ionic/angular';
import { ShoplistmapmodalComponent } from '../shoplistmapmodal/shoplistmapmodal.component';
import { UpdateGoogleAnalyticsService } from '../services/updateGoogleAnalyticsService';
import { MetaDataController } from '../services/metaDataController';

@Injectable({
  providedIn: 'root'
})
export class ShopListMapModalService {
  private isShopListOpened: boolean = false;
  constructor(
    public pds: PagedataService,
    private modalController: ModalController,
    private updateGoogleAnalyticsService: UpdateGoogleAnalyticsService,
    private metaDataController: MetaDataController
  ) {}

  // 店舗一覧・地図モーダルを開く
  public async openShopListMapModal(fnBrowserBack = function () {}) {
    if (this.isShopListOpened) {
      return;
    }
    this.isShopListOpened = true;
    // モーダルを表示する
    let deviceClass = this.pds.getIsIos() ? '__Ios__' : '__noIos__';
    const modal = await this.modalController.create({
      id: 'listMapModal', // 店舗詳細モーダルからcardモーダル指定するためのidを設定
      component: ShoplistmapmodalComponent,
      cssClass: ['card-modal', deviceClass],
      presentingElement: document.querySelector('#ion-page'),
      canDismiss: async function (data?: any, role?: string) {
        return role !== 'gesture';
      }
    });
    this.metaDataController.refreshTitleDescription();

    // googleアナリティクス計上
    this.updateGoogleAnalyticsService.accruePageviews('/shopselect', false, '店舗一覧');

    // モーダルを閉じた時の処理
    let modalResult: boolean | null = null;
    modal.onDidDismiss().then((result: { data: boolean; role: string }) => {
      this.isShopListOpened = false;
      this.metaDataController.refreshTitleDescription();
    });

    await modal.present();
    await modal.onDidDismiss();

    return modalResult;
  }
}
