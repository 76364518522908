import { LayoutData } from '../interfaces/layoutData.interface';
import { SalonPageData } from '../interfaces/salonPageData.interface';
import { FreePageData } from '../interfaces/freePageData.interface';
import { CampaignPageData } from '../interfaces/campaignPageData.interface';
import { GalleryPageData } from '../interfaces/galleryPageData.interface';
import { HomePageData } from '../interfaces/homePageData.interface';
import { MenuPageData } from '../interfaces/menuPageData.interface';
import { NewsPageData } from '../interfaces/newsPageData.interface';
import { NewsDetailPageData } from '../interfaces/newsDetailPageData.interface';
import { StaffPageData } from '../interfaces/staffPageData.interface';
import { StaffDetailPageData } from '../interfaces/staffDetailPageData.interface';
import { SalonData } from '../interfaces/salonData.interface';
import { DomainRelatedAccountData } from '../interfaces/domainRelatedAccountData.interface';

const initCommonPageData = {
  // ページに共通するデータ
  pageTitle: null,
  pageDescription: null,
  title: null,
  concept: null,
  conceptHtml: null,
  privateMsg: null,
  blockData: null,
  isDisplay: false,
  reserveTopUrl: null,
  isDisplayEndTitle: false,
  endTitle: null,
  altSalonName: null
};

export const initLayoutData: LayoutData = {
  salonData: null,
  menuHomeName: null,
  menuMenuName: null,
  menuStaffName: null,
  menuShopName: null,
  menuGalleryName: null,
  menuNewsName: null,
  menuCampaignName: null,
  menus: null,
  copyright: null,
  isDisplayAbout: null,
  isDisplayMenu: null,
  isDisplayStaff: null,
  isDisplayShop: null,
  isDisplayGallery: null,
  isDisplayNews: null,
  isDisplayCampaign: null,
  reserveTopUrl: null,
  isDisplayLogo: null,
  centerLogo: null,
  headerBg: null,
  appLike: null,
  mainVisual: null,
  theme: null,
  font: null,
  headingStyle: null,
  themeColor: null,
  title: null,
  catch: null,
  logoImage: null,
  faviconImage: null,
  splashImage: null,
  snsImage: null,
  shareLinkFacebook: null,
  shareLinkTwitter: null,
  shareLinkInstagram: null,
  animationType: null,
  icon192: null,
  icon512: null,
  appName: null,
  icon180: null,
  sprash6401336: null,
  sprash7501334: null,
  sprash12422208: null,
  sprash11252436: null,
  sprash8281792: null,
  sprash12422688: null,
  sprash15362048: null,
  sprash16682224: null,
  sprash16682388: null,
  sprash20482732: null,
  displayOrder: null,
  logoImageLand: null,
  logoType: null,
  icon192Pre: null,
  icon512Pre: null,
  icon180Pre: null,
  sprash6401336Pre: null,
  sprash7501334Pre: null,
  sprash12422208Pre: null,
  sprash11252436Pre: null,
  sprash8281792Pre: null,
  sprash12422688Pre: null,
  sprash15362048Pre: null,
  sprash16682224Pre: null,
  sprash16682388Pre: null,
  sprash20482732Pre: null,
  sprash15362048Land: null,
  sprash16682224Land: null,
  sprash16682388Land: null,
  sprash20482732Land: null,
  sprash15362048LandPre: null,
  sprash16682224LandPre: null,
  sprash16682388LandPre: null,
  sprash20482732LandPre: null,
  sprash11702532: null,
  sprash12842778: null,
  sprash11702532Pre: null,
  sprash12842778Pre: null,
  isPreview: null,
  footerTitle: null,
  isPointSystem: null,
  ucsUrl: null,
  gtmContainerId: null,
  gaAppendSalonName: null,
  sprash14882266: null,
  sprash14882266Pre: null,
  sprash14882266Land: null,
  sprash14882266LandPre: null,
  gaTrackingGa4Tag: null,
  sprash11792556: null,
  sprash12902796: null,
  sprash11792556Pre: null,
  sprash12902796Pre: null,
  sprash12062622: null,
  sprash13202868: null,
  sprash12062622Pre: null,
  sprash13202868Pre: null,
  shortName: null,
  pathNameMap: {},
  defaultSalonName: null,
  defaultSalonAccountName: null
};

export const initSalonPageData: SalonPageData = {
  ...initCommonPageData,
  photo: null,
  name: null,
  address: null,
  lon: null,
  lat: null,
  tel: null,
  businessHours: null,
  holiday: null,
  access: null,
  facilities: null,
  parkingLot: null,
  parkingLotHtml: null,
  creditCard: null,
  others: null,
  recruit: null,
  recruitHtml: null,
  salonGroupInfo: null,
  photoDisplayType: null,
  isDisplayPhoto: null,
  photoAlt: null,
  photoIframe: null
};

export const initFreePageData: FreePageData = {
  id: null,
  title: null,
  isDisplay: null,
  image: null,
  body: null,
  bodyHtml: null,
  imageHtml: null,
  imageDisplayType: null,
  isDisplayImage: null,
  imageAlt: null,
  imageIframe: null,
  homeConcept: null,
  homeImage: null,
  homeImageDisplayType: null,
  isDisplayHomeImage: null,
  homeImageAlt: null,
  homeImageIframe: null,
  homeImageHtml: null,
  privateMsg: null,
  blockData: null,
  webpImage: null,
  altSalonName: null
};

export const initCampaignPageData: CampaignPageData = {
  ...initCommonPageData,
  coupon: null,
  companyCoupon: null
};

export const initGalleryPageData: GalleryPageData = {
  ...initCommonPageData,
  gallery: null,
  category: null,
  isDisplayCategory: false,
  isDisplayStaff: false,
  brandGalleryData: [],
  isDisplayImageCover: false
};

export const initHomePageData: HomePageData = {
  pageTitle: null,
  pageDescription: null,
  layout: null,
  title: null,
  aboutTitle: null,
  aboutConcept: null,
  aboutConceptHtml: null,
  menuTitle: null,
  menuConcept: null,
  menuConceptHtml: null,
  staffTitle: null,
  staffConcept: null,
  staffConceptHtml: null,
  shopTitle: null,
  shopConcept: null,
  shopConceptHtml: null,
  galleryTitle: null,
  galleryConcept: null,
  galleryConceptHtml: null,
  isDisplayNews: null,
  newsTitle: null,
  newsConcept: null,
  newsConceptHtml: null,
  news: null,
  isDisplayCampaign: null,
  campaignTitle: null,
  campaignConcept: null,
  campaignConceptHtml: null,
  heroImagePc: null,
  heroImageSp: null,
  aboutImage: null,
  staffImage: null,
  shopImage: null,
  campaignImage: null,
  gallery: null,
  appLike: null,
  scrollEffect: null,
  twoColumns: null,
  noLogoHero: null,
  noLogoHeroStyle: null,
  noLogoOutside: null,
  reserveTopUrl: null,
  isDisplayAbout: true,
  isDisplayGallery: true,
  isDisplayMenu: true,
  isDisplayShop: true,
  isDisplayStaff: true,
  aboutImageDisplayType: null,
  isDisplayAboutImage: null,
  aboutImageAlt: null,
  aboutImageIframe: null,
  heroImagePcDisplayType: null,
  isDisplayHeroImagePc: null,
  heroImagePcAlt: null,
  heroImagePcIframe: null,
  menuImageDisplayType: null,
  isDisplayMenuImage: null,
  menuImageAlt: null,
  menuImageIframe: null,
  staffImageDisplayType: null,
  isDisplayStaffImage: null,
  staffImageAlt: null,
  staffImageIframe: null,
  shopImageDisplayType: null,
  isDisplayShopImage: null,
  shopImageAlt: null,
  shopImageIframe: null,
  campaignImageDisplayType: null,
  isDisplayCampaignImage: null,
  campaignImageAlt: null,
  campaignImageIframe: null,
  menus: null,
  privateMsg: null,
  blockData: null,
  emergencyNewsBlockData: null,
  webpHeroImagePc: null,
  webpAboutImage: null,
  webpMenuImage: null,
  webpStaffImage: null,
  webpShopImage: null,
  brandGalleryData: [],
  galleryDisplayImageCover: false,
  altSalonName: null
};

export const initMenuPageData: MenuPageData = {
  ...initCommonPageData,
  menu: null,
  viewCategorySetting: null,
  viewCategories: null,
  viewCategoryTreatments: null
};

export const initNewsPageData: NewsPageData = {
  ...initCommonPageData,
  news: null
};

export const initNewsDetailPageData: NewsDetailPageData = {
  ...initCommonPageData,
  id: null,
  news: null,
  newsPrev: null
};

export const initStaffPageData: StaffPageData = {
  ...initCommonPageData,
  isDisplayAllStaffImage: null,
  staffPerson: null
};

export const initStaffdetailPage: StaffDetailPageData = {
  ...initCommonPageData,
  staffPerson: null,
  staffPersonPrev: null,
  galleryDisplayImageCover: false
};

export const initSalonData: SalonData = {
  id: null,
  accountName: null,
  appEnabled: null,
  companyId: null,
  publishedDate: null,
  isPublic: null,
  name: null,
  csSalonId: null,
  ownDomain: null,
  viewCategoryEnabled: null,
  needMenuPage: false,
  isUseMenuFromCompany: false,
  isInstantReservationEnabled: false,
  instantReservationGroupName: null,
  instantReservationButtonNameIndex: null,
  companyName: null,
  announcementCompanyOrSalon: null
};

export const initDomainRelatedAccountData: DomainRelatedAccountData = {
  accountName: null,
  isCompany: false,
  isRedirect: false
};
