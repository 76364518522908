import { Component, HostListener, ViewChild } from '@angular/core';

import { Platform, AlertController, ModalController } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

import { PagedataService } from './services/pagedata.service';
import { APP_ENABLED, CookieData, LocalStorageData } from './pagedata';
import { ThemeData } from './interfaces/themeData.interface';
import { LayoutData } from './interfaces/layoutData.interface';
import { SalonData } from './interfaces/salonData.interface';
import { HomePageData } from './interfaces/homePageData.interface';
import { ParamForReservation } from './interfaces/paramForReservation.interface';
import { SalonPageData } from './interfaces/salonPageData.interface';
import { DomainRelatedAccountData } from './interfaces/domainRelatedAccountData.interface';
import { initLayoutData, initSalonPageData, initSalonData } from './initData/initData';
import { ReservationServiceProvider } from './services/ReservationServiceProvider';
import { RequestParamsService } from './services/requestParams.service';

import { MetaDataController } from './services/metaDataController';
import { NavigationController } from './services/navigationController';
import { NavigationEnd, Router, NavigationStart, NavigationExtras } from '@angular/router';
import { HeaderComponent } from './header/header.component';

import { ShopDetailModalService } from './services/shopDetailModal.service';
import { NoticeBlurActive } from './services/noticeBlurActive';
import { Subscription } from 'rxjs';
import { firebaseConfig } from 'src/firebase-config';
import { firebase } from '@firebase/app';
import { NotificationsService } from './services/notifications.service';
import { MyPageService } from './services/myPage.service';
import { HandShakeComponent } from './handshake/handshake.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { NoticeOpenLinkFromAppCom } from './services/noticeOpenLinkFromAppCom';
import { UpdateGoogleAnalyticsService } from './services/updateGoogleAnalyticsService';
import { AccountService } from './services/account.service';
import { WindowService } from './services/windowService';
import { NavigateService } from './services/navigate.service';
import { CookieService } from 'ngx-cookie-service';
import { LineNotificationStopNoticeComponent } from './modal/line-notification-stop-notice/line-notification-stop-notice.component';

// --------------------------
// src/assets/js/customColor.js
declare let lightenColor: any;
declare let HEXtoRGB: any;
// --------------------------
declare var require: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  themeData: ThemeData = {
    font: null,
    headingStyle: null,
    color: {
      accentColor: null,
      functionalColor: null,
      baseColor: null,
      attentionColor: null
    }
  };

  styles = {
    theme0101: false,
    theme0102: false,
    theme0103: false,
    theme0104: false,
    theme0105: false,

    theme0201: false,
    theme0202: false,
    theme0203: false,
    theme0204: false,
    theme0205: false,
    theme0206: false,

    theme0301: false,
    theme0302: false,
    theme0303: false,
    theme0304: false,
    theme0305: false,
    theme0306: false,
    theme0307: false,

    h_theme0101: false,
    h_theme0102: false,
    h_theme0103: false,

    h_theme0201: false,
    h_theme0202: false,
    h_theme0203: false,
    h_theme0204: false,
    h_theme0205: false,

    h_theme0301: false,
    h_theme0302: false,
    h_theme0303: false
  };

  layoutData: LayoutData = initLayoutData;
  shopDetailLayoutData: LayoutData = initLayoutData;
  intervalId: any;

  private subscription: Subscription;
  blurActiveFlg: boolean = false;
  private isUrlKey = false;
  modelName: string = null;
  osVersion: string = null;
  osName: string = null;
  osMajorVersion: number = null;
  public salonData: SalonData = initSalonData;
  public ogData: HomePageData = null;

  public accountCreating = false; // アカウント作成中
  private salonAccountName: string = null; // サロンアカウント名
  salonPageData: SalonPageData = initSalonPageData;
  navigationExtras: NavigationExtras = {
    queryParams: { p: 1 }
  };
  public isStandAlone: boolean = false;
  static readonly STAGING_COMPANY_DOMAIN: string = '.pwah.1cs-staging.jp';
  static readonly PRODUCTION_COMPANY_DOMAIN: string = '.pwah.1cs.jp';
  static readonly LOCAL_DOMAIN: string = '.localhost:4000';
  @ViewChild(HeaderComponent, { static: false }) appHeader: HeaderComponent;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public pds: PagedataService,
    public rs: ReservationServiceProvider,
    private requestParamsService: RequestParamsService,
    private metaDataController: MetaDataController,
    private router: Router,
    private noticeBlurActive: NoticeBlurActive,
    private notificationsService: NotificationsService,
    private myPageService: MyPageService,
    public navigationController: NavigationController,
    public alertController: AlertController,
    private modalController: ModalController,
    private clipboard: Clipboard,
    private updateGoogleAnalyticsService: UpdateGoogleAnalyticsService,
    private noticeOpenLinkFromAppCom: NoticeOpenLinkFromAppCom,
    private shopDetailModalService: ShopDetailModalService,
    public accountService: AccountService,
    private windowService: WindowService,
    private navigateService: NavigateService,
    private cookieService: CookieService
  ) {
    this.initializeApp();
    this.isStandAlone = this.pds.getIsStandAloneMode();
  }

  @HostListener('window:click', ['$event'])
  async onClickWindow(e: Event) {
    let clickElem = e.target as Element;
    // クリックされた箇所がヘッダーかどうか
    const isAppHeader = clickElem.closest('app-header') !== null;
    if (!isAppHeader) {
      // ヘッダー以外のクリックなら吹き出しを閉じる
      this.appHeader.closeNavList(false);
    }
    // 内部リンクと判断されるリスト
    let internalUrlList = [];
    for (let key in this.layoutData['pathNameMap']) {
      let url = '';
      if (key.match('gallery')) {
        let urlParams = key.split('_');
        url = urlParams[0];
        if (urlParams[1]) {
          url += '?brand_id=' + urlParams[1];
        }
      } else {
        url = key;
      }
      internalUrlList.push(url);
    }
    if (
      clickElem.tagName === 'A' &&
      !(clickElem.classList.contains('block__button') || clickElem.classList.contains('block__shopLink'))
    ) {
      // クリックした要素がaタグで、ボタン・店舗リンクのブロックでない場合
      console.log('【モーダルを使わないでリンクをクリックした時】（以降のconsole内容は、動作確認後削除）');
      if (this.shopDetailModalService.isShopDetailNav) {
        // 処理を中断（モーダル側でリンク処理を行うので、ここでは処理を行わない）
        console.log('モーダル側でリンク処理を行うので、【モーダルを使わないでリンクをクリックした時】の処理は中断');
      } else {
        console.log('モーダルを開いていないので、処理続行');
        let href = clickElem.getAttribute('href');
        let isOpenNewTab = clickElem.getAttribute('target') === '_blank';
        let hrefVals = [];
        if (href !== null) {
          if (href.indexOf('?') !== -1) {
            // テキストエディターから追加したリンク用（内部リンク）
            // 「/news?s=XXXX」のようにsパラメータが残っている
            hrefVals = href.split('?');
          } else {
            // ブロック追加のボタン（内部リンク）用
            // 「/news」のようにsパラメータなし
            hrefVals[0] = href;
          }
        } else {
          // hrefが設定されていない場合、処理を中断（以降の処理も行わない）
          e.preventDefault();
          return false;
        }

        // javascript:void(0);以外のリンクを処理
        if (hrefVals[0].indexOf('javascript:void(0);') === -1) {
          // メールアドレス、電話番号じゃない場合に処理を続行
          if (hrefVals[0].indexOf('mailto:') === -1 && hrefVals[0].indexOf('tel:') === -1) {
            // 処理を中断
            e.preventDefault();
            var $companyContents = clickElem.closest('.companyContents');
            if ($companyContents) {
              // companyContentsクラスを含む要素内のリンクを処理する
              let hrefVals2 = [];
              hrefVals2 = href.split('/');
              if (hrefVals2[0] === '') {
                // 「/」で区切った際の先頭が空文字なので内部リンクと判断し、すべて外部リンクとして処理する
                await this.navigateService.navigateUrlForCompanyContents(
                  href,
                  isOpenNewTab,
                  $companyContents.attributes['data-salon_id'].value
                );
              } else {
                // 外部リンク遷移を行う
                await this.navigateService.navigateExternalUrl(href, isOpenNewTab);
              }
            } else {
              if (internalUrlList.includes(href)) {
                // 内部リンク遷移を行う
                this.navigateService.navigateInternalUrl(href, isOpenNewTab);
              } else {
                // 外部リンク遷移を行う
                await this.navigateService.navigateExternalUrl(href, isOpenNewTab);
              }
            }
          }
        }
      }
    }
  }

  // tokenが保存できてなかった時に表示するメッセージ
  async openFailureCreateAccountMessage(): Promise<void> {
    const homepageUrl = `${location.origin}/home`;
    const message = `予期せぬエラーが発生しました。<br>
    お手数ですが、アプリの再起動をお願いします。<br>
    <br>
    このエラーが出続ける場合は、アプリを削除して、 下のURLをブラウザで開き、再度インストールをお願いします。<br>
    ${homepageUrl}</a>
    `;

    const alert = await this.alertController.create({
      cssClass: 'failureCreateAccountMsg',
      message: message,
      buttons: [
        {
          text: 'OK',
          handler: () => {}
        }
      ]
    });

    await alert.present();
    await alert.onDidDismiss();
  }

  async openMessagePage(): Promise<void> {
    const alert = await this.alertController.create({
      cssClass: '',
      message: 'メッセージを開きます',
      buttons: [
        {
          text: 'OK',
          handler: () => {}
        }
      ]
    });

    await alert.present();
    await alert.onDidDismiss();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    this.load();
    this.intervalId = setInterval(() => {
      //　オンラインかつ最終の更新から2時間経っている場合データ問い合わせ（2時間経過の確認は1分おき）
      this.isNeedreload();
    }, 60000);
    this.subscription = this.noticeBlurActive.sharedDataSource$.subscribe((msg) => {
      this.blurActiveFlg = msg;
    });
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.subscription.unsubscribe();
  }

  private async isNeedreload() {
    if (await this.pds.isNeedreload()) {
      location.reload();
    }
  }

  private async load() {
    const params = this.requestParamsService.getRequestParams();
    await this.accountService.fetchAndSetSalonFromOwnDomain();
    const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
    this.salonAccountName = !!accountData.accountName ? accountData.accountName : null;
    this.pds.updatePrerenderReady(false);

    let favoriteShopList = JSON.parse(this.pds.getData(LocalStorageData.FAVORITE_SALON_ID_LIST));
    if (favoriteShopList == null) {
      favoriteShopList = [];
      this.pds.setData(LocalStorageData.FAVORITE_SALON_ID_LIST, JSON.stringify(favoriteShopList));
    }
    this.isUrlKey = params.key !== null;

    // standAloneモードの場合だけtrueに
    this.accountCreating = this.pds.getIsStandAloneMode();

    // デバイスのモデル名とOSバージョンを設定する
    await this.pds.setDeviceInfo();
    this.modelName = this.pds.getModelName();
    this.osVersion = await this.pds.getOsVersion();
    this.osName = this.pds.getOsName();
    this.osMajorVersion = this.pds.getOsMajorVersion();

    // urlログインはスマホでないと使えない
    if (this.isUrlKey && !(this.osName == 'iOS' || this.osName == 'Android')) {
      this.notSmartphoneAlert();
    }

    this.layoutData = await this.pds.getLayoutData();
    this.navigateService.layoutData = this.layoutData;
    if (params.shopDetail != null) {
      this.shopDetailLayoutData = await this.pds.getLayoutData(params.shopDetail);
    }
    // GCSのAPIを呼ぶときはここより↓で
    this.themeData = await this.pds.getThemeData();
    this.salonData = await this.pds.getSalonData();
    this.ogData = await this.pds.getHomeDataForOG();
    this.salonPageData = await this.pds.getSalonPageData();

    // 構造化データを生成する
    if (this.pds.getIsCompany()) {
      // 本部は住所・電話番号をデータに持たないので、構造化データにも入れない
      this.metaDataController.createBeautySalonStructuredData(
        this.salonPageData['name'],
        null,
        null,
        this.salonData.ownDomain
      );
    } else {
      this.metaDataController.createBeautySalonStructuredData(
        this.salonPageData['name'],
        this.salonPageData['address'],
        this.salonPageData['tel'],
        this.salonData.ownDomain
      );
    }

    // Webフォントのロード
    this.pds.loadFonts(this.layoutData['font']);
    // 本部の場合、GAの計上データは必ず本部名をつける
    const forceShowCompanyName = params.shopDetail == null && this.salonData.appEnabled === 5 ? true : false;
    await this.setTags(this.layoutData, this.salonData, this.ogData, forceShowCompanyName);
    this.setColor(this.themeData);

    // アプリではない場合、Cookieの店舗データを初期化する（isAnnouncementSalonに関わらず実行する。起動時に分岐させるため）
    if (!this.pds.isPwaApp && !this.shopDetailModalService.isShopDetailNav) {
      this.cookieService.delete(CookieData.INITIAL_APP_OPENED_SALON_TITLE, '/');
    }

    // 本部PWAの場合、isAnnouncementSalonを更新する（エンタープライズ＋アドバンス、ベーシック、アドバンスは使わない機能なのでfalse固定）
    if (accountData.isCompany) {
      if (this.salonData.announcementCompanyOrSalon === this.pds.ANNOUNCEMENT_SALON) {
        this.pds.setIsAnnouncementSalon(true);
      }
    }
    // Cookieに店舗のデータがあればその店舗をモーダルで開く（アプリかつ再読み込みでないかつプッシュ通知クリックではない場合のみ）
    if (
      this.pds.isPwaApp &&
      this.salonData.appEnabled === APP_ENABLED.PWA_APP_ENTERPRISE_COMPANY &&
      !this.pds.isReload &&
      params.pageName !== MyPageService.MESSAGE_LIST &&
      this.pds.getIsAnnouncementSalon()
    ) {
      const exists = this.cookieService.check(CookieData.INITIAL_APP_OPENED_SALON_TITLE);
      let shopName = null;
      if (exists) {
        shopName = this.cookieService.get(CookieData.INITIAL_APP_OPENED_SALON_TITLE);
        // Cookieの保存期限を100年にしているが、ブラウザの制限で短命の場合があるので、ローカルストレージにも保存しておく
        this.pds.setData(LocalStorageData.INITIAL_APP_OPENED_SALON_TITLE, shopName);
      } else {
        // Cookieにない場合、ローカルストレージから店舗名が取れるか試す
        shopName = this.pds.getData(LocalStorageData.INITIAL_APP_OPENED_SALON_TITLE);
      }
      if (shopName !== null) {
        this.shopDetailModalService.openShopDetailModal(shopName, 'home', {});
      }
    }

    // スタンドアロンモードならアカウントを作成する（公開状態が非公開の場合はアカウント作成しない）
    if (
      this.pds.getIsStandAloneMode() &&
      !this.isUrlKey &&
      this.salonData.isPublic &&
      this.salonData.publishedDate != null
    ) {
      // アカウント作成
      const token = await this.createAccount();
      this.accountCreating = false;
      // アカウント作成に失敗したらここからの処理は行わない
      if (token == null) {
        return;
      }

      const statusResult = await this.rs
        .findNotificationStatus(this.salonAccountName, token, this.pds.getIsCompany())
        .catch((error) => {
          console.error(error);
          return false;
        });
      // LINE連携を利用しているユーザー
      const lineNotificationEnabled = statusResult.isLineMessagingEnabled && statusResult.lineDeliverStatus > 0;
      // プッシュ通知関連
      const isSupportedNotification = await this.notificationsService.isSupportedNotification();
      if (isSupportedNotification) {
        // LINE通知を利用している場合、今後はLINE通知が送られなくなることをお知らせする

        // プッシュトークンの登録
        const isRegistered = await this.notificationsService.registerPushToken(lineNotificationEnabled);

        // プッシュ通知タップでメッセージが開く処理
        if (this.pds.getIsAndroid() && params.pageName === MyPageService.MESSAGE_LIST) {
          // パラメータにpageNameが指定されていたらurlからパラメータを削除して開く
          // プッシュ通知から直接メッセージを開くために利用する
          const url = new URL(window.location.href);
          url.searchParams.delete('pageName');
          history.replaceState(null, '', url.href);

          this.myPageService.isPushTap = true;
          // ポップアップブロック回避のためにダイアログを出す
          await this.openMessagePage();
          const param: ParamForReservation = {
            staffId: -1,
            lastReservation: -1
          };
          this.myPageService.authenticateKey(MyPageService.MESSAGE_LIST, param);
        }
      } else {
        // LINE通知終了のお知らせ表示
        const osCheck = this.pds.getIsIos();
        const lineCheck = lineNotificationEnabled;
        if (osCheck && lineCheck) {
          let type = 0; // 1か2ならお知らせを出す
          const hasAnnounced: boolean = this.pds.getData(LocalStorageData.LINE_NOTIFICATION_TERMINATED) || false;
          const dontShowAgain = this.pds.getData(LocalStorageData.LINE_NOTIFICATION_DO_NOT_SHOW_AGAIN) || false;
          const mailAddressCheck =
            statusResult.mailAddress != null && statusResult.mailAddress !== '' && statusResult.mailDeliverStatus === 1;
          if (!hasAnnounced) {
            type = 1;
            this.pds.setData(LocalStorageData.LINE_NOTIFICATION_TERMINATED, true);
          } else if (!mailAddressCheck && !dontShowAgain) {
            type = 2;
          }

          if (type > 0) {
            const modal = await this.modalController.create({
              component: LineNotificationStopNoticeComponent,
              cssClass: 'dialog-container line-notification-modal-height',
              backdropDismiss: false,
              componentProps: {
                type // 1 or 2
              }
            });

            modal
              .onDidDismiss()
              .then(async (res) => {
                if (res.data === 'open') {
                  await this.appHeader.openMypageAndNotificationPage();
                } else if (res.data === 'close') {
                  // 閉じるだけで何もしない
                }
              })
              .catch((error) => {
                console.error('Error handling modal dismissal:', error);
              });

            await modal.present();
            await modal.onDidDismiss();
          }
        }
      }

      // 初めてアプリを開くときにはハンドシェイクの画面を出す。
      const isFirstStart = this.pds.getData(LocalStorageData.IS_FIRST_START);
      this.pds.setData(LocalStorageData.IS_FIRST_START, true);
      // 顧客と紐づいているかチェック
      const salons = await this.rs.findSalonList(token).catch((error) => {
        return null;
      });
      const salonData = await this.pds.getSalonData();
      const salonId = salonData.csSalonId;
      const currentSalon = salons == null ? null : salons.find((salon: any) => salon.id === salonId);
      // 初めての起動で顧客と紐づいていない場合はハンドシェイクのダイアログ画面を出す
      if (!isFirstStart && currentSalon != null && currentSalon.appSalon.customerId < 0) {
        // 初めての起動でも顧客と紐づいている場合は出さない。当機能リリース前から利用しているユーザーも出さない条件に該当
        this.myPageService.openModalHandshake(currentSalon.viewReceiptReservationApps);
      }
    }
    this.accountCreating = false;

    // メッセージ未読数更新
    await this.myPageService.findUnreadMessageCount(null);

    if (
      this.pds.getData(LocalStorageData.IS_SHOWN_OLD_ALERT) == null &&
      this.pds.getIsStandAloneMode() &&
      !this.isUrlKey
    ) {
      if (this.osName === 'iOS' && this.osMajorVersion !== null && this.osMajorVersion < 14) {
        this.oldOSAlert(
          'ご利用のiOSのバージョンは推奨されていません。<br >このままご利用にもなれますが、動作が遅く、実用に耐えられない場合がございます。iOS14以降でのご利用を推奨します。'
        );
      }
      if (this.osName === 'Android' && this.osMajorVersion !== null && this.osMajorVersion < 10) {
        this.oldOSAlert(
          'ご利用のAndroidのバージョンは推奨されていません。<br >このままご利用にもなれますが、動作が遅く、実用に耐えられない場合がございます。Android10以降でのご利用を推奨します。'
        );
      }
    }

    // アプリかつ期限切れの場合、アクセスできなくなるよアラートを表示
    if (this.pds.isPwaApp && this.pds.isInaccessibleSoon) {
      this.inaccessibleSoonAlert(this.pds.newUrlForEnterprise);
    } else if (this.pds.isPwaApp && this.pds.isInaccessible) {
      this.inaccessibleAlert(this.pds.newUrlForEnterprise);
    }
    await this.pds.setUpdatedDate();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateGoogleAnalyticsService.accruePageviews(event.urlAfterRedirects, false);
      }
    });
  }

  // OSが古いよアラート
  async oldOSAlert(msg: string) {
    const alert = await this.alertController.create({
      cssClass: 'oldOSAlertMsg',
      message: msg,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.pds.setData(LocalStorageData.IS_SHOWN_OLD_ALERT, 'yes');
          }
        }
      ]
    });
    await alert.present();
  }

  // スマホじゃないよアラート
  private async notSmartphoneAlert() {
    const alert = await this.alertController.create({
      cssClass: 'oldOSAlertMsg',
      message:
        'スマホで認証コードを使い閲覧した時のみ、マイページや予約変更、キャンセルなどができます。<br><br>' +
        'PCでは、ホームページの閲覧や新規予約のみができます。',
      buttons: [
        {
          text: 'OK'
        }
      ]
    });
    await alert.present();
  }

  // もうすぐアクセスできなくなるよアラート
  private async inaccessibleSoonAlert(newURL: string) {
    const alert = await this.alertController.create({
      cssClass: 'inaccessibleAlertMsg',
      message:
        'このアプリは間もなくご利用いただけなくなります。<br>' +
        'お手数ですが、以下のURLをブラウザ（safariやChromeなど）で開き、新しいアプリのインストールをお願いします。<br>' +
        newURL,
      buttons: [
        {
          text: 'URLをコピー',
          cssClass: 'order-first',
          handler: (e) => {
            this.clipboard.copy(newURL);
            // アラートは閉じない
            e.stopPropagation();
            e.preventDefault();
          }
        },
        {
          text: 'そのまま利用する',
          cssClass: 'order-last'
        }
      ]
    });
    await alert.present();
  }

  // もうアクセスできないよアラート
  private async inaccessibleAlert(newURL: string) {
    const alert = await this.alertController.create({
      cssClass: 'inaccessibleAlertMsg',
      message:
        'このアプリはご利用いただけなくなりました。<br>' +
        'お手数ですが、以下のURLをブラウザ（safariやChromeなど）で開き、新しいアプリのインストールをお願いします。<br>' +
        newURL,
      buttons: [
        {
          text: 'URLをコピー',
          cssClass: 'order-first',
          handler: (e) => {
            this.clipboard.copy(newURL);
            // アラートは閉じない
            e.stopPropagation();
            e.preventDefault();
          }
        }
      ]
    });
    await alert.present();
  }

  private setTags(data: LayoutData, salonData: SalonData, ogData: HomePageData, forceShowCompanyName: boolean) {
    console.log('set tags & WebManifest start!');

    function decideImgType(url: string) {
      let type = '';
      if (url) {
        if (url.indexOf('.jpg') !== -1) {
          type = 'image/jpg';
        } else if (url.indexOf('.jpeg') !== -1) {
          type = 'image/jpeg';
        } else if (url.indexOf('.png') !== -1) {
          type = 'image/png';
        } else if (url.indexOf('.gif') !== -1) {
          type = 'image/gif';
        } else if (url.indexOf('.svg') !== -1) {
          type = 'image/svg+xml';
        } else if (url.indexOf('.ico') !== -1) {
          type = 'image/x-icon';
        }
      }
      return type;
    }

    // アイコンは一旦「"type": "image/png"」で固定する
    // let iconType = decideImgType(this.layoutData.icon192);
    let faviconType = decideImgType(data.faviconImage);
    var appName;
    var icon180;
    var icon192;
    var icon512;
    var sprash6401336;
    var sprash7501334;
    var sprash12422208;
    var sprash11252436;
    var sprash8281792;
    var sprash12422688;
    var sprash15362048;
    var sprash16682224;
    var sprash16682388;
    var sprash20482732;
    var sprash11702532;
    var sprash12842778;
    var sprash15362048land;
    var sprash16682224land;
    var sprash16682388land;
    var sprash20482732land;
    var sprash14882266;
    var sprash14882266land;
    var sprash11792556;
    var sprash12902796;
    var sprash12062622;
    var sprash13202868;

    if (this.pds.getPreview()) {
      appName = '(仮) ' + data.appName;
      icon180 = data.icon180Pre;
      icon192 = data.icon192Pre;
      icon512 = data.icon512Pre;
      sprash6401336 = data.sprash6401336Pre;
      sprash7501334 = data.sprash7501334Pre;
      sprash12422208 = data.sprash12422208Pre;
      sprash11252436 = data.sprash11252436Pre;
      sprash8281792 = data.sprash8281792Pre;
      sprash12422688 = data.sprash12422688Pre;
      sprash15362048 = data.sprash15362048Pre;
      sprash16682224 = data.sprash16682224Pre;
      sprash16682388 = data.sprash16682388Pre;
      sprash20482732 = data.sprash20482732Pre;
      sprash11702532 = data.sprash11702532Pre;
      sprash12842778 = data.sprash12842778Pre;
      sprash15362048land = data.sprash15362048LandPre;
      sprash16682224land = data.sprash16682224LandPre;
      sprash16682388land = data.sprash16682388LandPre;
      sprash20482732land = data.sprash20482732LandPre;
      sprash14882266 = data.sprash14882266Pre;
      sprash14882266land = data.sprash14882266LandPre;
      sprash11792556 = data.sprash11792556Pre;
      sprash12902796 = data.sprash12902796Pre;
      sprash12062622 = data.sprash12062622Pre;
      sprash13202868 = data.sprash13202868Pre;
      this.metaDataController.updateLinkForWebManifest('/preview_webmanifest.json');
    } else {
      appName = data.appName;
      icon180 = data.icon180;
      icon192 = data.icon192;
      icon512 = data.icon512;
      sprash6401336 = data.sprash6401336;
      sprash7501334 = data.sprash7501334;
      sprash12422208 = data.sprash12422208;
      sprash11252436 = data.sprash11252436;
      sprash8281792 = data.sprash8281792;
      sprash12422688 = data.sprash12422688;
      sprash15362048 = data.sprash15362048;
      sprash16682224 = data.sprash16682224;
      sprash16682388 = data.sprash16682388;
      sprash20482732 = data.sprash20482732;
      sprash11702532 = data.sprash11702532;
      sprash12842778 = data.sprash12842778;
      sprash15362048land = data.sprash15362048Land;
      sprash16682224land = data.sprash16682224Land;
      sprash16682388land = data.sprash16682388Land;
      sprash20482732land = data.sprash20482732Land;
      sprash14882266 = data.sprash14882266;
      sprash14882266land = data.sprash14882266Land;
      sprash11792556 = data.sprash11792556;
      sprash12902796 = data.sprash12902796;
      sprash12062622 = data.sprash12062622;
      sprash13202868 = data.sprash13202868;
      this.metaDataController.updateLinkForWebManifest('/webmanifest.json');
    }
    this.metaDataController.updateLinkForFavicon(data.faviconImage, faviconType);
    this.metaDataController.updateAppName(appName);
    if (this.osName == 'iOS') {
      this.metaDataController.updateIosIcon(icon180);
      this.metaDataController.updateIosSplash(
        sprash6401336,
        sprash7501334,
        sprash12422208,
        sprash11252436,
        sprash8281792,
        sprash12422688,
        sprash15362048,
        sprash16682224,
        sprash16682388,
        sprash20482732,
        sprash11702532,
        sprash12842778,
        sprash15362048land,
        sprash16682224land,
        sprash16682388land,
        sprash20482732land,
        sprash14882266,
        sprash14882266land,
        sprash11792556,
        sprash12902796,
        sprash12062622,
        sprash13202868
      );
    }

    // googleアナリティクスを設定
    var key;
    if (this.router.url.indexOf('?') != -1) {
      key = this.router.url.substring(0, this.router.url.indexOf('?'));
    } else {
      key = this.router.url;
    }
    var layoutDataPageName = this.layoutData['pathNameMap'][key];
    var pageName = (<any>this.layoutData)[layoutDataPageName];
    const ga4Tag = this.layoutData.gaTrackingGa4Tag;
    if (data.gaAppendSalonName || forceShowCompanyName) {
      let salonName = salonData.name;
      pageName = salonName + ' - ' + pageName;
    }
    this.updateGoogleAnalyticsService.setLayoutData(data);
    this.updateGoogleAnalyticsService.setSalonData(salonData);
    if (data.gtmContainerId !== null && data.gtmContainerId !== '') {
      this.metaDataController.addGTM(data.gtmContainerId);
      this.updateGoogleAnalyticsService.createGTMPageview(pageName, key);
    } else if (ga4Tag) {
      this.metaDataController.addGAtag(pageName, key, ga4Tag);
    }

    // カノニカルタグを更新
    if (this.salonData.ownDomain) {
      this.metaDataController.createOrUpdateCanonical(this.salonData.ownDomain, this.router.url);
    }

    // OG初回更新
    this.metaDataController.updateOGFirst(
      this.pds.pwaEnv,
      this.salonData.accountName,
      this.salonData.ownDomain,
      this.router.url,
      this.layoutData['title'],
      this.layoutData['catch'],
      ogData,
      salonData.appEnabled === APP_ENABLED.PWA_APP_ENTERPRISE_COMPANY
    );

    if (this.pds.pwaEnv === 'staging') {
      this.metaDataController.addNoIndex();
    }

    console.log('set tags & WebManifest end!!');
  }

  private setColor(data: ThemeData) {
    const fontStyle = data.font;
    (<any>this.styles)[fontStyle] = true;
    const headingStyle = data.headingStyle;
    (<any>this.styles)[headingStyle] = true;
    const lighten20 = lightenColor(data.color.accentColor, 20);
    const lighten50 = lightenColor(data.color.accentColor, 50);
    const $ = (el: any) => document.querySelector(el);
    $(':root').style.setProperty('--accent-color', data.color.accentColor);
    $(':root').style.setProperty('--accent-color-rgb', HEXtoRGB(data.color.accentColor));
    $(':root').style.setProperty('--functional-color', data.color.functionalColor);
    $(':root').style.setProperty('--functional-color-rgb', HEXtoRGB(data.color.functionalColor));
    $(':root').style.setProperty('--base-color', data.color.baseColor);
    $(':root').style.setProperty('--base-color-rgb', HEXtoRGB(data.color.baseColor));
    $(':root').style.setProperty('--attention-color', data.color.attentionColor);
    $(':root').style.setProperty('--attention-color-rgb', HEXtoRGB(data.color.attentionColor));
    $(':root').style.setProperty('--lighten-20-color', `rgb(${lighten20})`);
    $(':root').style.setProperty('--lighten-50-color', `rgb(${lighten50})`);

    // 店舗詳細モーダルを開いていない場合は、モーダル用のテーマカラーセットは行わない
    // （モーダル用のテーマカラーセットが先に行われることがあるので、意図しない上書き防止のために）
    if (!this.shopDetailModalService.isShopDetailNav) {
      // モーダル表示用のテーマカラーの初期値も同じ値をセットする（未セットの場合、チラついて表示されるので）
      $(':root').style.setProperty('--accent-color-modal', data.color.accentColor);
      $(':root').style.setProperty('--accent-color-rgb-modal', HEXtoRGB(data.color.accentColor));
      $(':root').style.setProperty('--functional-color-modal', data.color.functionalColor);
      $(':root').style.setProperty('--functional-color-rgb-modal', HEXtoRGB(data.color.functionalColor));
      $(':root').style.setProperty('--lighten-20-color-modal', `rgb(${lighten20})`);
      $(':root').style.setProperty('--lighten-50-color-modal', `rgb(${lighten50})`);
      $(':root').style.setProperty('--base-color-modal', data.color.baseColor);
      $(':root').style.setProperty('--base-color-rgb-modal', HEXtoRGB(data.color.baseColor));
      $(':root').style.setProperty('--attention-color-modal', data.color.attentionColor);
      $(':root').style.setProperty('--attention-color-rgb-modal', HEXtoRGB(data.color.attentionColor));
    }

    this.metaDataController.updateThemeColor(data.color.baseColor);
  }

  // ホームから起動したらアカウントごとのtokenを発行して返す
  private async createAccount(): Promise<string> {
    const accountData: DomainRelatedAccountData = this.accountService.getSalonNameAndIsCompany();
    const salonName: string = accountData.accountName != null ? accountData.accountName : null;

    // エンタープライズ+アドバンスの場合はエンタープライズと同じ処理
    const isAdvance = this.salonData['appEnabled'] === APP_ENABLED.PWA_APP_ADVANCE && this.salonData['companyId'];
    let companyName = null;
    if (this.pds.getIsCompany()) {
      companyName = salonName;
    } else if (isAdvance) {
      const companyData = await this.pds.getCompanySalonDataById(this.salonData.id);
      companyName = companyData.accountName;
    }
    this.salonData.companyName = companyName;

    const userData = await this.pds.getDataPromise(LocalStorageData.USER_DATA);
    let token: string = userData != null ? userData.token : null;
    // localStorageにtokenがなければindexedDBからも取得を試みる
    if (!token) {
      token = await this.pds.getTokenFromIndexedDb();
      // localStorageに戻す
      if (token) {
        this.pds.setData(LocalStorageData.USER_DATA, { token: token });
      }
    } else {
      // localStorageにtokenがあればindexedDBにも保存
      this.pds.setTokenToIndexedDb(token);
    }

    let appVersion: string = this.pds.getData(LocalStorageData.APP_VER);
    appVersion = appVersion.replace('v', ''); // "v"が含まれているので除いて数字だけにする
    const appType = 'pwaReservationApp';
    if (!this.modelName || !this.osName || !this.osVersion || !appVersion) {
      // 不要なデータが連続で作られることがある
      return;
    }

    // tokenはデバイスに保存する
    if (!token) {
      // 新規インストール
      if (this.pds.getIsCompany() || isAdvance) {
        // エンタープライズアプリ
        const result = await this.rs
          .registerEnterpriseApp(companyName, this.modelName, this.osName, null, this.osVersion, appVersion, appType)
          .catch((error) => {
            // 本部取得エラーで処理終了
            console.error(error);
            return;
          });
        token = result.token;
      } else {
        // 通常アプリ
        const result = await this.rs
          .registerApp(salonName, this.modelName, this.osName, null, this.osVersion, appVersion, appType)
          .catch((error) => {
            console.error(error);
            return;
          });
        if (typeof result === 'string' && result !== null && result.length) {
          token = result;
        }
      }

      if (token !== null && token.length) {
        this.pds.setData(LocalStorageData.USER_DATA, { token: token });
        // indexedDBにもtokenを保存
        this.pds.setTokenToIndexedDb(token);
      }

      // tokenがlocalStorageに保存できているかチェック
      // 1秒待つ
      return new Promise((resolve) => {
        setTimeout(() => {
          this.pds.getDataPromise(LocalStorageData.USER_DATA).then(async (userDataTemp) => {
            const localStorageToken: string = userDataTemp ? userDataTemp.token : null;
            if (localStorageToken) {
              // 保存成功
              resolve(token);
            } else {
              // 失敗
              // localStorageTokenがないので作成したばかりのtokenを渡す
              this.rs.executeSendSupportForm(token, salonName, 'tokenの保存に失敗');
              this.openFailureCreateAccountMessage();
              resolve(null);
            }
          });
        }, 1000);
      });
    } else {
      // 2回目以降のアプリ起動
      const result = await this.rs
        .updateApp(token, this.modelName, this.osName, null, this.osVersion, appVersion)
        .catch((error) => {
          return;
        });

      // エンタープライズは系列店をお気に入り追加
      if (this.pds.getIsCompany() || isAdvance) {
        const result = await this.rs.addSalonFromEnterprise(token, companyName, -1).catch((error) => {
          // 本部取得エラーで処理終了
          console.error(error);
          return;
        });
      }
      if (result && result.token) {
        return result.token;
      } else {
        return null;
      }
    }
  }
}
