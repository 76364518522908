<ion-content class="modal-alert-content">
  <div class="title ion-text-center" *ngIf="type === 1; else type2Title">「通知設定」変更のお願い</div>
  <ng-template #type2Title>
    <div class="title ion-text-center">「通知設定」メールアドレス設定のお願い</div>
  </ng-template>
  <div class="message" *ngIf="type === 1; else type2Body">
    これまではアプリのプッシュ通知の代わりにLINEで通知を行っていましたが、今後はLINEでの通知を停止し、メール通知のみに変更いたします。<br /><br />
    まだメール通知を設定していない場合は「通知設定」でメール通知をONにし、メールアドレスをご登録ください。<br />
  </div>
  <ng-template #type2Body
    ><div class="message">
      「これまではアプリのプッシュ通知の代わりにLINEで通知を行っていましたが、今後はLINEでの通知を停止し、メール通知のみに変更いたしました。<br /><br />
      「通知設定」でメール通知をONにし、メールアドレスをご登録ください。<br />
    </div>
  </ng-template>
  <div class="buttons auto-layout reverse-at-vertical">
    <ion-button fill="solid" mode="ios" shape="round" color="light" (click)="openNotificationSetting()">
      通知設定へ
    </ion-button>
    <ion-button fill="solid" mode="ios" shape="round" color="dark" (click)="close()"> OK </ion-button>
  </div>
  <ion-item lines="none" *ngIf="type === 2">
    <ion-checkbox labelPlacement="end" justify="start" [(ngModel)]="dontShowAgain">今後表示しない</ion-checkbox>
  </ion-item>
</ion-content>
